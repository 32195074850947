import {
  FormControl,
  FormLabel,
  Heading,
  Select,
  SimpleGrid,
  Stack,
} from '@chakra-ui/react'
import { map } from 'lodash'
import useCurrentReport from '../../hooks/useCurrentReport'
import { waterTypes } from '../../store/useStore'
import ValidatedInput from '../../components/ValidatedInput'
import useUpdateReportDoc from '../../hooks/useUpdateReportDoc'

const WaterQuestions = () => {
  const updateReport = useUpdateReportDoc()
  const report = useCurrentReport()

  const handleInputChange = (field: string, value: any) => {
    updateReport({ [field]: value })
  }

  return (
    <Stack>
      <Heading size="md">Water Questions</Heading>
      <FormControl isRequired>
        <SimpleGrid columns={[1, 2]}>
          <FormLabel>What kind of water are you drinking?</FormLabel>
          <Select
            placeholder="Choose Type"
            onChange={(e) => {
              handleInputChange(
                'waterType',
                e.target.value as keyof typeof waterTypes
              )
            }}
            value={report?.waterType || ''}
          >
            {map(waterTypes, (label, id) => {
              return (
                <option value={id} key={id}>
                  {label}
                </option>
              )
            })}
          </Select>
        </SimpleGrid>
      </FormControl>
      <ValidatedInput
        label="How many ounces (oz) of water do you drink on average per day? (Integer) (1 oz = 30mL)"
        value={report?.waterAmount ?? null}
        min={0}
        max={200}
        isRequired={true}
        onChange={(value) => handleInputChange('waterAmount', value)}
      />
    </Stack>
  )
}

export default WaterQuestions
