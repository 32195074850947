import { useEffect } from 'react'
import {
  CollectionReference,
  collection,
  getFirestore,
  onSnapshot,
  query,
} from 'firebase/firestore'
import Doctor from '../types/Doctor'
import useStore from '../store/useStore'

const useFetchDoctors = () => {
  useEffect(() => {
    onSnapshot(
      query(
        collection(getFirestore(), 'doctors') as CollectionReference<Doctor>
      ),
      (doctors) => {
        useStore.setState({ doctors })
      }
    )
  }, [])
}

export default useFetchDoctors
