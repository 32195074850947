import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text
} from '@chakra-ui/react'
import {
  CollectionReference,
  QueryDocumentSnapshot,
  collection,
  collectionGroup,
  getDocs,
  getFirestore,
  query,
  where
} from 'firebase/firestore'
import { orderBy } from 'lodash'
import moment from 'moment'
import { useState } from 'react'
import UsersTable from '../components/UsersTable'
import useHandleError from '../hooks/useHandleError'
import { MonthlyReport } from '../store/useStore'
import FirestoreUser from '../types/FirestoreUser'

export default () => {
  const [month, setMonth] = useState(moment().format('YYYY-MM'))
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<{
    month: string
    complete: QueryDocumentSnapshot<FirestoreUser>[]
    incomplete: QueryDocumentSnapshot<FirestoreUser>[]
  }>()
  const handleError = useHandleError()

  return (
    <Stack spacing={6}>
      <Heading>Completion</Heading>
      <form
        onSubmit={e => {
          e.preventDefault()
          setLoading(true)
          getCompletion(month)
            .then(data => setData({ ...data, month }))
            .catch(handleError)
            .finally(() => setLoading(false))
        }}
      >
        <Stack maxW='container.sm'>
          <FormControl isRequired>
            <FormLabel>Enter month in YYYY-MM format</FormLabel>
            <Input
              value={month}
              onChange={e => setMonth(e.target.value)}
              placeholder='Month'
            />
          </FormControl>
          <Box>
            <Button
              isDisabled={loading || !month.length}
              isLoading={loading}
              type='submit'
            >
              Submit
            </Button>
          </Box>
        </Stack>
      </form>
      {data && (
        <Stack spacing={4}>
          <Heading fontSize='lg'>Results for: {data.month}</Heading>
          <Stack spacing={2}>
            <Heading fontSize='md'>
              Complete ({data.complete.length}):
            </Heading>
            <UsersTable docs={data.complete} lastNameFirst />
            <Text>{data.complete.map(d => d.get('email')).join(',')}</Text>
          </Stack>
          <Stack spacing={2}>
            <Heading fontSize='md'>
              Incomplete ({data.incomplete.length}):
            </Heading>
            <UsersTable docs={data.incomplete} lastNameFirst />
            <Text>
              {data.incomplete.map(d => d.get('email')).join(',')}
            </Text>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

async function getCompletion(month: string) {
  const patientsSnap = await getDocs(
    query(
      collection(
        getFirestore(),
        'users'
      ) as CollectionReference<FirestoreUser>,
      where('roles', 'array-contains', 'patient'),
      where('profile', '!=', null)
    )
  )

  const patients = patientsSnap.docs.filter(doc => {
    const user = doc.data()
    const diagnosis = user.profile?.diagnosis
    return (
      diagnosis === 'als' ||
      diagnosis === 'als-probable' ||
      diagnosis === 'pls'
    )
  })

  // console.log('got', patients.size, 'patients')

  const reports = await getDocs(
    query(
      collectionGroup(
        getFirestore(),
        'reports'
      ) as CollectionReference<MonthlyReport>,
      where('id', '==', month)
    )
  )

  // console.log('got', reports.size, 'reports')

  const uidsWithCompleteReports: string[] = []

  reports.forEach(doc => {
    const report = doc.data()
    const uid = doc.ref.parent.parent?.id
    if (!uid) {
      throw new Error(
        'Could not get uid for report doc with path ' + doc.ref.path
      )
    }

    let complete = true
    if (report.alsfrs?.score === undefined) {
      complete = false
    }
    if (report.alssq?.score === undefined) {
      complete = false
    }
    if (!report.clinicalObservations) {
      complete = false
    }
    if (!report.diets) {
      complete = false
    }
    if (!report.mentalStrategies) {
      complete = false
    }
    if (!report.nonSupplementMedications) {
      complete = false
    }
    if (
      !report.panas?.modifiedPanas?.complete &&
      !report.panas?.main?.complete
    ) {
      complete = false
    }
    if (!report.physicalStrategies) {
      complete = false
    }
    if (!report.stool?.stool?.complete) {
      complete = false
    }
    if (!report.stool?.urine?.complete) {
      complete = false
    }
    if (!report.supplements) {
      complete = false
    }
    if (report.vitalStats?.weight === undefined) {
      complete = false
    }

    if (complete) {
      uidsWithCompleteReports.push(uid)
    }
  })

  // console.log('complete report count:', uidsWithCompleteReports.length)

  const emails: {
    complete: string[]
    incomplete: string[]
  } = { complete: [], incomplete: [] }

  const docs: {
    complete: QueryDocumentSnapshot<FirestoreUser>[]
    incomplete: QueryDocumentSnapshot<FirestoreUser>[]
  } = {
    complete: [],
    incomplete: []
  }

  patients.forEach(doc => {
    const email = doc.get('email')

    if (uidsWithCompleteReports.includes(doc.id)) {
      emails.complete.push(email)
      docs.complete.push(doc)
    } else {
      emails.incomplete.push(email)
      docs.incomplete.push(doc)
    }
  })

  docs.complete = orderBy(docs.complete, doc => {
    const data = doc.data()
    return `${data.profile?.searchLastName}, ${data.profile?.searchFirstName}`
  })
  docs.incomplete = orderBy(docs.incomplete, doc => {
    const data = doc.data()
    return `${data.profile?.searchLastName}, ${data.profile?.searchFirstName}`
  })

  // console.log('COMPLETE:', emails.complete.length)
  // console.log(emails.complete.join(','))

  // console.log('INCOMPLETE:', emails.incomplete.length)
  // console.log(emails.incomplete.join(','))

  return docs
}
