// LiveSessions.tsx
import { useMemo } from 'react'
import {
  Checkbox,
  HStack,
  SimpleGrid,
  Stack,
  Tag,
  Text,
} from '@chakra-ui/react'
import { groupBy, keys, map, values } from 'lodash'
import moment, { Moment } from 'moment'
import { deleteField } from 'firebase/firestore'
import useStore, { MonthlyReport, Reports } from '../../store/useStore'

const LiveSessions = ({
  reports,
  month,
  update,
}: {
  reports: Reports
  month?: string
  update?: (partial: Partial<MonthlyReport>) => Promise<void>
}) => {
  const omitSundays = useStore((state) => state.meta?.omitSundays || [])
  const report = (!!update && !!month && reports[month]) || undefined

  const days = useMemo(() => {
    const moments: Moment[] = []

    const months = month ? [month] : keys(reports)

    months.forEach((reportingFor) => {
      const m = moment(new Date(reportingFor + '-15'))

      for (let i = -30; i < 30; i++) {
        const m2 = m.clone().add(i, 'days')

        if (m2.month() === m.month() && [0, 1, 4].includes(m2.weekday())) {
          moments.push(m2)
        }
      }
    })

    return groupBy(moments, (m) => m.weekday())
  }, [reports, month])

  const noneChecked =
    report?.sundays &&
    !values(report.sundays).some((val) => val === 'live' || val === 'recording')

  return (
    <Stack>
      {!!update && (
        <Text>Which Healing ALS sessions did you attend this month? *</Text>
      )}
      {!!update && report && (
        <HStack spacing={2}>
          <Checkbox
            isChecked={noneChecked}
            onChange={(e) => {
              if (noneChecked) {
                console.log('deleting field')
                update({
                  sundays: deleteField() as any,
                })
              } else {
                update({
                  sundays: {},
                })
              }
            }}
          />
          <Text>Did not attend or view any sessions</Text>
        </HStack>
      )}

      <Stack spacing={1}>
        {map(days, (days, dayOfWeek) => {
          return (
            <SimpleGrid key={dayOfWeek} columns={[2, 3, 5]} spacing={1}>
              {days.map((moment) => {
                const date = moment.format('YYYY-MM-DD')
                const omitted = omitSundays.includes(date)

                const report = reports[moment.format('YYYY-MM')]
                const status = report?.sundays?.[date]

                return (
                  <Stack
                    p={2}
                    key={date}
                    spacing={1}
                    backgroundColor={omitted ? 'gray.200' : 'gray.50'}
                  >
                    <Text fontSize="sm">
                      <Tag
                        size="sm"
                        backgroundColor={
                          moment.weekday() === 1
                            ? 'green.200'
                            : moment.weekday() === 4
                            ? 'blue.200'
                            : 'purple.200'
                        }
                      >
                        {moment.format('ddd')}
                      </Tag>{' '}
                      {moment.format('l')}
                    </Text>
                    {omitted ? (
                      <Text fontSize="sm">No Meeting</Text>
                    ) : (
                      <Stack spacing={0}>
                        <HStack spacing={2}>
                          <Checkbox
                            size="sm"
                            isDisabled={omitted || !update}
                            isChecked={status === 'live'}
                            onChange={(e) => {
                              update?.({
                                sundays: {
                                  ...report?.sundays,
                                  [date]: e.target.checked ? 'live' : 'none',
                                },
                              })
                            }}
                          />
                          <Text fontSize="xs">Attended Live</Text>
                        </HStack>
                        <HStack spacing={2}>
                          <Checkbox
                            size="sm"
                            isDisabled={omitted || !update}
                            isChecked={status === 'recording'}
                            onChange={(e) => {
                              update?.({
                                sundays: {
                                  ...report?.sundays,
                                  [date]: e.target.checked
                                    ? 'recording'
                                    : 'none',
                                },
                              })
                            }}
                          />
                          <Text fontSize="xs">Watched Recording</Text>
                        </HStack>
                      </Stack>
                    )}
                  </Stack>
                )
              })}
            </SimpleGrid>
          )
        })}
      </Stack>
    </Stack>
  )
}

export default LiveSessions
