import {
  Box,
  Button,
  Center,
  Flex,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Tag,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { QueryDocumentSnapshot } from 'firebase/firestore'
import { Link } from 'react-router-dom'
import FirestoreUser, {
  shortDiagnosisTitles,
  shortRoleTitles,
} from '../types/FirestoreUser'

export default ({
  docs,
  lastNameFirst,
}: {
  docs: QueryDocumentSnapshot<FirestoreUser>[]
  lastNameFirst?: boolean
}) => {
  return (
    <TableContainer>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>ID</Th>
            <Th>Studies</Th>
            <Th>Diagnosis</Th>
            <Th>Role</Th>
            <Th>Phone</Th>
            <Th>Email</Th>
            <Th>Country</Th>
          </Tr>
        </Thead>
        <Tbody>
          {docs.map((doc) => {
            const { profile, patientId, email, roles, studies } = doc.data()
            if (!profile) return null

            return (
              <Tr key={doc.id}>
                <Td>
                  <Link to={`/admin/users/${doc.id}`}>
                    <Button variant="link">
                      {lastNameFirst
                        ? `${profile.lastName}, ${profile.firstName}`
                        : `${profile.firstName} ${profile.lastName}`}
                    </Button>
                  </Link>
                </Td>
                <Td>{patientId}</Td>
                <Td>
                  <Stack spacing={1}>
                    {studies
                      ? studies.map((study, index) => (
                          <Tag
                            key={index}
                            size="md"
                            borderRadius="full"
                            variant="solid"
                            colorScheme="green"
                            width="90px"
                          >
                            <Flex justify="space-between" width="100%">
                              <Center>{study}</Center>
                            </Flex>
                          </Tag>
                        ))
                      : ''}
                  </Stack>
                </Td>
                <Td>{shortDiagnosisTitles[profile.diagnosis]}</Td>
                <Td>{roles.map((r) => shortRoleTitles[r]).join('/')}</Td>
                <Td>{profile.phoneNumber}</Td>
                <Td>{email}</Td>
                <Td>{profile.country}</Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
