import {
  extendTheme,
  withDefaultColorScheme,
  withDefaultVariant,
} from '@chakra-ui/react'
import { MultiSelectTheme } from 'chakra-multiselect'

const theme = extendTheme(
  {
    fonts: {
      heading: `'Karla', sans-serif`,
      body: `'Karla', sans-serif`,
    },
    components: {
      MultiSelect: MultiSelectTheme,
    },
  },
  withDefaultColorScheme({
    colorScheme: 'purple',
  }),
  withDefaultVariant({
    variant: 'left-accent',
    components: ['Alert'],
  })
)

export default theme
