import useReport from './useReport'
import useReportDocId, { useLastMonthReportDocId } from './useReportDocId'

export const useLastMonthReport = () => {
  const lastMonth = useLastMonthReportDocId()
  return useReport(lastMonth)
}

export default () => {
  const month = useReportDocId()
  return useReport(month)
}
