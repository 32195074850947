import { useEffect } from 'react'
import { collection, getFirestore, onSnapshot } from 'firebase/firestore'
import useStore from '../store/useStore'

const useFetchContent = () => {
  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(getFirestore(), 'content'),
      (snap) => {
        const content: any = {}

        snap.forEach((doc) => {
          if (doc.id === 'purpleBubbles') {
            const data = doc.data()
            content.purpleBubbles = data.contentBlocks
          } else {
            content[doc.id] = doc.data()
          }
        })

        useStore.setState({ content })
      }
    )

    return () => unsubscribe()
  }, [])
}

export default useFetchContent
