import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  Flex,
  Input,
  Link,
  Select,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { getAuth } from 'firebase/auth'
import {
  DocumentReference,
  DocumentSnapshot,
  arrayRemove,
  arrayUnion,
  deleteField,
  getFirestore,
  onSnapshot,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { keys, map } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Link as ReactRouterLink, useParams } from 'react-router-dom'
import useHandleError from '../hooks/useHandleError'
import FirestoreUser, {
  Role,
  diagnosisTitles,
  roleTitles,
} from '../types/FirestoreUser'
import userDoc from '../utils/userDoc'
import { doc, getDoc } from 'firebase/firestore'
import ConfigList from '../types/ConfigList'
import useIsSuperAdmin from '../hooks/useIsSuperAdmin'
import { formatDateFull, formatDateMonth } from '../utils/formatDate'

export default () => {
  const [snap, setSnap] = useState<DocumentSnapshot<FirestoreUser>>()
  const [currentUserRoles, setCurrentUserRoles] = useState<Role[]>([])
  const params = useParams<{ id: string }>()
  const handleError = useHandleError()
  const isSuperAdmin = useIsSuperAdmin()

  const uid = useMemo(() => {
    return params.id || 'impossible'
  }, [params])

  const ref = useMemo(() => {
    return userDoc(uid)
  }, [uid])

  useEffect(() => {
    onSnapshot(ref, setSnap, handleError)
  }, [ref, handleError])

  useEffect(() => {
    const fetchCurrentUserRoles = async () => {
      const currentUser = getAuth().currentUser
      if (currentUser) {
        const currentUserRef = doc(
          getFirestore(),
          'users',
          currentUser.uid
        ) as DocumentReference<FirestoreUser>
        const currentUserSnap = await getDoc(currentUserRef)
        const currentUserData = currentUserSnap.data()
        if (currentUserData) {
          setCurrentUserRoles(currentUserData.roles)
        }
      }
    }

    fetchCurrentUserRoles().catch(handleError)
  }, [handleError])

  const user = snap?.data()
  const profile = user?.profile

  if (!user) return null

  const tags = user.studies || []

  const handleRemoveTag = async (tag: string) => {
    try {
      const userRef = doc(
        getFirestore(),
        'users',
        uid
      ) as DocumentReference<FirestoreUser>
      await updateDoc(userRef, { studies: arrayRemove(tag) })
    } catch (e) {
      handleError(e)
    }
  }

  return (
    <Stack spacing={5}>
      <Stack spacing={0}>
        <Heading>
          {profile ? `${profile.firstName} ${profile.lastName}` : 'No Profile'}
        </Heading>
        <Text>#{user.patientId}</Text>
        <Stack direction="row" spacing={2}>
          {tags.map((tag, index) => (
            <Tag
              key={index}
              size="md"
              borderRadius="full"
              variant="solid"
              colorScheme="green"
              width="110px"
            >
              <Flex justify="space-between" width="100%">
                <Center>
                  <TagLabel>{tag}</TagLabel>
                </Center>
                <TagCloseButton onClick={() => handleRemoveTag(tag)} />
              </Flex>
            </Tag>
          ))}
        </Stack>
      </Stack>
      {user && (
        <Stack spacing={0}>
          <Text>{user.email}</Text>
          <Text>{user.profile?.phoneNumber}</Text>
          <Text>
            Joined Registry:{' '}
            {user.createdAt
              ? isSuperAdmin
                ? formatDateFull(user.createdAt)
                : formatDateMonth(user.createdAt)
              : 'N/A'}
          </Text>
          <Box>
            <ReactRouterLink to={`/admin/users/${uid}/print`}>
              <Button variant="link">Print Records</Button>
            </ReactRouterLink>
          </Box>
          <Box>
            <ReactRouterLink to={`/admin/users/${uid}/edit-profile`}>
              <Button variant="link">Edit Profile / Upload Docs</Button>
            </ReactRouterLink>
          </Box>
          <Email uid={uid} />
          <Password uid={uid} />
          <Studies uid={uid} />
        </Stack>
      )}
      <Stack spacing={2}>
        <Heading size="md">Roles</Heading>
        {keys(roleTitles).map((role) => {
          return (
            <RoleCheckbox
              key={role}
              role={role as Role}
              user={user}
              docRef={ref}
            />
          )
        })}
      </Stack>
      {!!profile?.diagnosis && (
        <Stack spacing={3}>
          <Heading size="md">
            Diagnosis: {diagnosisTitles[profile.diagnosis]}
          </Heading>
          <Stack spacing={0}>
            <Text>Uploaded Documents: </Text>
            {profile.diagnosisDocumentation?.map((url, index) => {
              return (
                <Link color="purple.600" key={url} href={url} isExternal>
                  Document #{index + 1}
                </Link>
              )
            })}
          </Stack>
          <Checkbox
            isChecked={!!profile.diagnosisVerified}
            onChange={() => {
              setDoc(
                ref,
                {
                  profile: {
                    diagnosisVerified: !profile.diagnosisVerified,
                  },
                },
                { merge: true }
              )
            }}
          >
            Diagnosis Verified
          </Checkbox>
        </Stack>
      )}
      <AccountStatus
        lockedDate={user.lockedDate}
        onChange={async (lockedDate) => {
          return updateDoc(ref, {
            lockedDate: lockedDate || deleteField(),
          })
        }}
      />
    </Stack>
  )
}

const AccountStatus = ({
  lockedDate,
  onChange,
}: {
  onChange: (lockedDate?: string) => Promise<void>
  lockedDate?: string
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const cancelRef = useRef<any>()
  const [input, setInput] = useState('')
  const toast = useToast()
  const handleError = useHandleError()
  const [loading, setLoading] = useState(false)

  return (
    <Stack spacing={3}>
      <Heading size="md">Deceased?</Heading>
      {lockedDate ? (
        <>
          <Text>Yes: {lockedDate}</Text>
          <Box>
            <Button
              variant="link"
              onClick={() => {
                if (window.confirm('Remove deceased status?')) {
                  onChange(undefined)
                }
              }}
            >
              Change
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Text>No</Text>
          <Box>
            <Button variant="link" onClick={onOpen}>
              Change
            </Button>
          </Box>
        </>
      )}
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Set Date</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              <Stack>
                <Alert>This user will no longer receive reminder emails.</Alert>
                <FormControl>
                  <FormLabel>Date</FormLabel>
                  <Input
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    type="date"
                  />
                </FormControl>
              </Stack>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} colorScheme="gray">
                Cancel
              </Button>
              <Button
                ml={3}
                isDisabled={loading || input.length === 0}
                isLoading={loading}
                onClick={async () => {
                  if (!input) {
                    return toast({
                      status: 'warning',
                      description: 'Please enter a date.',
                    })
                  }

                  setLoading(true)

                  try {
                    await onChange(input)
                    onClose()
                    toast({
                      status: 'success',
                      description: 'Set date successfully.',
                    })
                    setInput('')
                  } catch (e) {
                    handleError(e)
                  } finally {
                    setLoading(false)
                  }
                }}
              >
                Submit
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Stack>
  )
}

const RoleCheckbox = ({
  role,
  user,
  docRef: ref,
}: {
  role: Role
  user: FirestoreUser
  docRef: DocumentReference
}) => {
  const checked = user.roles.includes(role)
  const toast = useToast()

  return (
    <Checkbox
      isChecked={checked}
      onChange={() => {
        const currentUser = getAuth().currentUser
        if (
          ['admin', 'superadmin'].includes(role) &&
          ref.id === currentUser?.uid
        ) {
          return toast({
            status: 'warning',
            title: 'Warning',
            description: "You can't change your own admin status!",
          })
        }

        updateDoc(ref, {
          roles: checked ? arrayRemove(role) : arrayUnion(role),
        })
      }}
    >
      {roleTitles[role]}
    </Checkbox>
  )
}

const Email = ({ uid }: { uid: string }) => {
  const { isOpen, onOpen, onClose: _onClose } = useDisclosure()
  const cancelRef = useRef<any>()
  const handleError = useHandleError()
  const [input, setInput] = useState('')
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const onClose = () => {
    setInput('')
    _onClose()
  }

  return (
    <>
      <Box>
        <Button variant="link" onClick={onOpen}>
          Change Email
        </Button>
      </Box>
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Change Email</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              <FormControl>
                <FormLabel>New Email</FormLabel>
                <Input
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  type="email"
                />
              </FormControl>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} colorScheme="gray">
                Cancel
              </Button>
              <Button
                ml={3}
                isDisabled={loading || input.length === 0}
                isLoading={loading}
                onClick={async () => {
                  setLoading(true)

                  try {
                    const changeEmail = httpsCallable(
                      getFunctions(),
                      'changeEmail'
                    )
                    await changeEmail({ uid, email: input })
                    onClose()
                    toast({
                      status: 'success',
                      description:
                        'Successfully changed email to ' + input.toLowerCase(),
                    })
                  } catch (e) {
                    handleError(e)
                  } finally {
                    setLoading(false)
                  }
                }}
              >
                Submit
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

const Password = ({ uid }: { uid: string }) => {
  const { isOpen, onOpen, onClose: _onClose } = useDisclosure()
  const cancelRef = useRef<any>()
  const handleError = useHandleError()
  const [input, setInput] = useState('')
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const onClose = () => {
    setInput('')
    _onClose()
  }

  return (
    <>
      <Box>
        <Button variant="link" onClick={onOpen}>
          Change Password
        </Button>
      </Box>
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Change Password</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              <FormControl>
                <FormLabel>New Password</FormLabel>
                <Input
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                />
              </FormControl>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} colorScheme="gray">
                Cancel
              </Button>
              <Button
                ml={3}
                isDisabled={loading || input.length === 0}
                isLoading={loading}
                onClick={async () => {
                  setLoading(true)

                  try {
                    const changePassword = httpsCallable(
                      getFunctions(),
                      'changePassword'
                    )
                    await changePassword({ uid, password: input })
                    onClose()
                    toast({
                      status: 'success',
                      description:
                        'Successfully changed password to ' +
                        input.toLowerCase(),
                    })
                  } catch (e) {
                    handleError(e)
                  } finally {
                    setLoading(false)
                  }
                }}
              >
                Submit
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

const Studies = ({ uid }: { uid: string }) => {
  const { isOpen, onOpen, onClose: _onClose } = useDisclosure()
  const cancelRef = useRef<any>()
  const handleError = useHandleError()
  const [selectedStudy, setSelectedStudy] = useState('')
  const [studies, setStudies] = useState<{ itemId: string; text: string }[]>([])
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  useEffect(() => {
    const fetchStudies = async () => {
      const docId = 'studies'
      const ref = doc(
        getFirestore(),
        'config',
        docId
      ) as DocumentReference<ConfigList>
      const snap = await getDoc(ref)
      const data = snap.data()
      if (data) {
        const items = map(data.value, (text, itemId) => ({ itemId, text }))
        setStudies(items)
      }
    }

    fetchStudies().catch(handleError)
  }, [handleError])

  const onClose = () => {
    setSelectedStudy('')
    _onClose()
  }

  const handleSubmit = async () => {
    setLoading(true)
    console.log(selectedStudy)
    try {
      const userRef = doc(
        getFirestore(),
        'users',
        uid
      ) as DocumentReference<FirestoreUser>
      await updateDoc(userRef, {
        studies: arrayUnion(selectedStudy),
      })
      onClose()
      toast({
        status: 'success',
        description: 'Successfully added study.',
      })
    } catch (e) {
      handleError(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Box>
        <Button variant="link" onClick={onOpen}>
          Add Studies
        </Button>
      </Box>
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Change Studies</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              <FormControl>
                <FormLabel>New Studies</FormLabel>
                <Select
                  placeholder="Select study"
                  value={selectedStudy}
                  onChange={(e) => {
                    setSelectedStudy(e.target.value)
                  }}
                >
                  {studies.map((study) => (
                    <option key={study.itemId} value={study.text}>
                      {study.text}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} colorScheme="gray">
                Cancel
              </Button>
              <Button
                ml={3}
                isDisabled={loading || selectedStudy.length === 0}
                isLoading={loading}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
