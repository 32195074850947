import { Button, Heading, Stack, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export default () => {
  return (
    <Stack spacing={6}>
      <Stack>
        <Heading>Excellent job!</Heading>
        <Text>You have taken one more step to healing.</Text>
      </Stack>
      <Link to='/'>
        <Button size='lg'>I Am Healing!</Button>
      </Link>
    </Stack>
  )
}
