import { onSnapshot } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import useStore from '../store/useStore'
import FirestoreUser from '../types/FirestoreUser'
import userDoc from '../utils/userDoc'

export default (uid: string) => {
  const [user, setUser] = useState<FirestoreUser | undefined>(() => {
    const { user, firestoreUser } = useStore.getState()
    if (user?.uid === uid) {
      return firestoreUser
    } else return undefined
  })

  useEffect(() => {
    const unsub = onSnapshot(userDoc(uid), snap => {
      setUser(snap.data())
    })
    return () => unsub()
  }, [uid])

  return user
}
