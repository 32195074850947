import { useEffect } from 'react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import useStore from '../store/useStore'

const useAuth = () => {
  useEffect(() => {
    const auth = getAuth()
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      useStore.setState({ user })
    })
    return () => unsubscribe()
  }, [])
}

export default useAuth
