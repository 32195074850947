import { Box, Button, Heading, Image, Stack, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export default () => {
  return (
    <Stack
      as={Box}
      textAlign='center'
      spacing={[3, 4]}
      py={{ base: 20, md: 36 }}
      align='center'
    >
      <Image
        src='/lotus.jpeg'
        objectFit='cover'
        height={140}
        width={140}
        rounded='full'
      />
      <Heading
        fontWeight={600}
        fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
        lineHeight='110%'
      >
        {'Heal. '}
        <Text as='span' color='purple.500'>
          With Us.
        </Text>
      </Heading>
      <Text
        fontSize={{ base: 'md', sm: 'lg', md: '2xl' }}
        color={'gray.700'}
      >
        This first-of-its-kind Healing ALS Registry is{' '}
        <Text fontWeight='bold' as='span'>
          designed by PALS
        </Text>{' '}
        to help you and medical professionals track what is working to
        reverse ALS and what isn't. This will benefit everyone fighting
        this disease.
      </Text>
      <Stack spacing={5}>
        <Link to='/sign-up'>
          <Button size='lg'>Sign Up</Button>
        </Link>
        <Stack>
          <Text>Already have an account?</Text>
          <Link to='/sign-in'>
            <Button variant='link' color='black'>
              Sign In
            </Button>
          </Link>
        </Stack>
      </Stack>
    </Stack>
  )
}
