import useFirestoreUser from '../hooks/useFirestoreUser'
import useStore from '../store/useStore'
import ErrorPage from './ErrorPage'

export default () => {
  const user = useStore(s => s.user)
  const firestoreUser = useFirestoreUser()

  if (user && !firestoreUser) {
    return null
  }

  return <ErrorPage title='404 Error' subtitle='Page not found.' />
}
