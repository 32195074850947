// src/utils/dataValidation.ts
export const keepNumberInRange = (
  num: any,
  min = 0,
  max = 100
): number | null => {
  if (num === '') {
    return null // Return null for empty input
  }

  const theNumber = Number(num)

  if (isNaN(theNumber)) {
    return null // Return null for invalid numbers
  }

  const absNumber = Math.abs(theNumber)

  if (absNumber >= min && absNumber <= max) {
    return absNumber
  } else if (absNumber > max) {
    return max
  }

  return min
}
