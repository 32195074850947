// useSaveContent.ts
import { useToast } from '@chakra-ui/react'
import { doc, setDoc, getFirestore } from 'firebase/firestore'
import { ContentItem, Collection } from '../../../types/contentManagement'

const useSaveContent = (
  collection: Collection,
  collectionItems: ContentItem[],
  setCollectionItems: (items: ContentItem[]) => void,
  closeModal: () => void,
  title: string,
  text: string,
  status: 'adding' | 'editing' | ''
) => {
  const toast = useToast()

  const handleSave = async () => {
    try {
      if (!title.trim()) {
        toast({
          title: 'Error',
          description: 'Title cannot be empty.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        return
      }

      if (
        status === 'adding' &&
        collectionItems.some((item) => item.id === title)
      ) {
        toast({
          title: 'Error',
          description: 'An item with this title already exists.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        return
      }

      const newCollectionItems = [...collectionItems]
      const newItem: ContentItem = { id: title, text }
      if (status === 'adding') {
        newCollectionItems.push(newItem)
      } else if (status === 'editing') {
        const index = newCollectionItems.findIndex((item) => item.id === title)
        if (index !== -1) {
          newCollectionItems[index].text = text
        }
      }
      setCollectionItems(newCollectionItems)
      await setDoc(doc(getFirestore(), 'content', collection.docName), {
        contentBlocks: newCollectionItems,
      })

      toast({
        title: 'Success',
        description: `The ${title} content block has been saved`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      closeModal()
    } catch (error) {
      toast({
        title: 'Error',
        description: 'An error occurred while saving the content block.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return {
    handleSave,
  }
}

export default useSaveContent
