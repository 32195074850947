import React from 'react'
import { Stack, Heading, FormLabel } from '@chakra-ui/react'
import FormInput from '../../components/FormInput'
import ValidatedInput from '../../components/ValidatedInput'

interface CourseConfigSectionProps {
  title: string
  numberLabel: string
  value: number | null
  setNumberValue: (value: number | null) => void
  namesLabel: string
  namesValue: string[]
  handleNameChange: (index: number, value: string) => void
}

const CourseConfigSection: React.FC<CourseConfigSectionProps> = ({
  title,
  numberLabel,
  value,
  setNumberValue,
  namesLabel,
  namesValue,
  handleNameChange,
}) => {
  return (
    <Stack spacing={6}>
      <Heading size="md">{title}</Heading>
      <ValidatedInput
        label={numberLabel}
        min={0}
        max={20}
        inputWidth={'55%'}
        value={value}
        onChange={(value: number | null) => setNumberValue(value)}
        inline={false}
      />

      {value && (
        <>
          <FormLabel>{namesLabel}</FormLabel>
          {namesValue.slice(0, value).map((name, index) => (
            <FormInput
              key={index}
              type="text"
              value={name}
              inputWidth={'55%'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleNameChange(index, e.target.value)
              }
            />
          ))}
        </>
      )}
    </Stack>
  )
}

export default CourseConfigSection
