import React from 'react'
import { Box, Text } from '@chakra-ui/react'

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear()

  return (
    <Box as="footer" py={4} textAlign="left" bg="gray.200">
      <Text pl="20px">
        © {currentYear} Healing ALS and Healing Advocates | You Can Heal&reg; |
        All rights reserved
      </Text>
    </Box>
  )
}

export default Footer
