// CollectionsContent.tsx
import React, { useState } from 'react'
import useStore from '../../../store/useStore'
import { Collection, ContentItem } from '../../../types/contentManagement'
import { Box, Heading, Text, Button, Stack, useToast } from '@chakra-ui/react'
import ModalComponent from './ModalComponent'
import AlertDialogComponent from './AlertDialogComponent'
import ContentItemComponent from './ContentItem'
import useModal from './useModal'
import useAlertDialog from './useAlertDialog'
import useSaveContent from './useSaveContent'
import useDeleteContent from './useDeleteContent'

interface CollectionsContentProps {
  collection: Collection
}

const CollectionsContent: React.FC<CollectionsContentProps> = ({
  collection,
}) => {
  const {
    isModalOpen,
    status,
    title,
    text,
    setTitle,
    setText,
    openModal,
    closeModal,
  } = useModal()
  const {
    isAlertOpen,
    itemToDelete,
    cancelRef,
    openAlertDialog,
    closeAlertDialog,
  } = useAlertDialog()
  const [collectionItems, setCollectionItems] = useState(
    useStore((s) => s.content?.[collection.docName]) || []
  )
  const { handleSave } = useSaveContent(
    collection,
    collectionItems,
    setCollectionItems,
    closeModal,
    title,
    text,
    status
  )
  const { handleDeleteContent } = useDeleteContent(
    collection,
    collectionItems,
    setCollectionItems,
    closeAlertDialog
  )
  const toast = useToast()

  const handleDeleteClick = () => {
    if (itemToDelete) {
      handleDeleteContent(itemToDelete)
    } else {
      toast({
        title: 'Error',
        description: 'No item selected for deletion.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <Box w="100%" p={4} h="100%" overflowY="auto">
      <Box borderWidth="1px" borderRadius="lg" p={4} mb={4}>
        <Heading size="md">{collection.name}</Heading>
        <Text mt={2}>{collection.description}</Text>
        <Button mt={4} colorScheme="purple" onClick={() => openModal('adding')}>
          Add new content for {collection.name}
        </Button>
      </Box>
      <Stack spacing={4}>
        {collectionItems.map((content: ContentItem) => (
          <ContentItemComponent
            key={content.id}
            content={content}
            onEdit={(content) => openModal('editing', content)}
            onDelete={(content) => openAlertDialog(content)}
          />
        ))}
      </Stack>

      <ModalComponent
        isOpen={isModalOpen}
        onClose={closeModal}
        status={status}
        title={title}
        text={text}
        setTitle={setTitle}
        setText={setText}
        handleSave={handleSave}
        collectionName={collection.name}
      />

      <AlertDialogComponent
        isOpen={isAlertOpen}
        onClose={closeAlertDialog}
        onDelete={handleDeleteClick}
        cancelRef={cancelRef}
      />
    </Box>
  )
}

export default CollectionsContent
