import { Flex, Image, Stack, Text } from '@chakra-ui/react'

export default () => {
  return (
    <Stack>
      <Flex justify='center'>
        <Image
          src='/lotus.jpeg'
          objectFit='cover'
          height={120}
          width={120}
          rounded='full'
        />
      </Flex>
      <Text textAlign='center' fontWeight='bold' fontSize='2xl'>
        Healing ALS Registry
      </Text>
    </Stack>
  )
}
