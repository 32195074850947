import { EditIcon } from '@chakra-ui/icons'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react'
import { getAuth } from 'firebase/auth'
import { deleteDoc, doc, getFirestore } from 'firebase/firestore'
import moment from 'moment'
import { useState } from 'react'
import { FaVial } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import DashboardItem from '../components/DashboardItem'
import useFirestoreUser from '../hooks/useFirestoreUser'
import useIsTester from '../hooks/useIsTester'
import useReport from '../hooks/useReport'
import useStagesCount from '../hooks/useStagesCount'
import useStore from '../store/useStore'
import { isPANASReportComplete } from './PANAS'
import usePurpleBubblesText from '../hooks/usePurpleBubblesText'
import { getDietStatus } from '../utils/dietStatus'
import PurpleBubbleContent from '../components/PurpleBubbleContent'

export default () => {
  const m = moment()
  const dayOfMonthLimit = useStore((s) => s.meta?.dayOfMonthLimit || 15)

  const pastDeadline = m.date() > dayOfMonthLimit
  const [currentMonth, setCurrentMonth] = useState(pastDeadline ? true : false)
  const monthMoment = m.clone().add(currentMonth ? 0 : -1, 'months')
  const month = m
    .clone()
    .add(currentMonth ? 0 : -1, 'months')
    .format('YYYY-MM')
  const changeToMoment = m.clone().add(currentMonth ? -1 : 0, 'month')
  const user = useFirestoreUser()
  const report = useReport(month)
  const stagesCount = useStagesCount()
  const isTester = useIsTester()
  const dietStatus = getDietStatus(report)
  const purpleBubbleContent = usePurpleBubblesText('Dashboard')

  return (
    <Stack spacing={2}>
      <Stack spacing={0}>
        <Heading>My Dashboard</Heading>
        <HStack spacing={4}>
          <Text>Reporting for: {monthMoment.format('MMMM YYYY')}</Text>
          <Button
            variant="link"
            onClick={() => {
              setCurrentMonth(!currentMonth)
            }}
            isDisabled={pastDeadline}
          >
            Change to {changeToMoment.format('MMMM YYYY')}
            {pastDeadline ? ' (Past Deadline)' : ''}
          </Button>
        </HStack>
        <Text fontSize="sm" as="span" fontWeight="normal">
          Patient ID: #{user?.patientId}
        </Text>
      </Stack>
      {!user ? null : user.profile ? (
        <>
          {purpleBubbleContent?.text && (
            <PurpleBubbleContent text={purpleBubbleContent.text} />
          )}
          <Flex wrap="wrap">
            <Box mt={1} mr={2}>
              <Link to={`/report/${month}/1`}>
                <Button rightIcon={<EditIcon />}>
                  Do Your Monthly Check-In
                </Button>
              </Link>
            </Box>
            {isTester && (
              <Box mt={1} mr={2}>
                <Link to="/labs">
                  <Button variant="outline" rightIcon={<FaVial />}>
                    Lab Tests
                  </Button>
                </Link>
              </Box>
            )}
            <Box mt={1} mr={2}>
              <Link to="/profile">
                <Button colorScheme="gray">Update Profile</Button>
              </Link>
            </Box>
            {false && (
              <Box mt={1}>
                <Button
                  colorScheme="gray"
                  variant="outline"
                  onClick={() => {
                    const user = getAuth().currentUser
                    if (user) {
                      deleteDoc(
                        doc(
                          getFirestore(),
                          `users/${user.uid}/reports/${month}`
                        )
                      )
                    }
                  }}
                >
                  Delete Report (Dev Only)
                </Button>
              </Box>
            )}
          </Flex>
        </>
      ) : (
        <Stack spacing={3}>
          <Alert status="warning" variant="left-accent">
            <AlertIcon />
            <AlertDescription>
              You can't start doing reports until you create your profile.
            </AlertDescription>
          </Alert>
          <Box>
            <Link to="/profile">
              <Button colorScheme="gray">Create Profile</Button>
            </Link>
          </Box>
        </Stack>
      )}
      <SimpleGrid minChildWidth="300px" spacing={2}>
        <DashboardItem
          to={`/report/${month}/1`}
          title="ALSFRS-R"
          subtitle="Step #1"
          status={
            !report || !report.alsfrs
              ? 'unstarted'
              : report.alsfrs.score === undefined
              ? 'inProgress'
              : 'complete'
          }
        />
        <DashboardItem
          to={`/report/${month}/2`}
          title="ALSSQ"
          subtitle="Step #2"
          status={
            !report || !report.alssq
              ? 'unstarted'
              : report.alssq.score === undefined
              ? 'inProgress'
              : 'complete'
          }
        />
        <DashboardItem
          to={`/report/${month}/3`}
          title="PANAS-SF"
          subtitle="Step #3"
          status={
            !report || !report.panas
              ? 'unstarted'
              : isPANASReportComplete(report.panas)
              ? 'complete'
              : 'inProgress'
          }
        />
        <DashboardItem
          to={`/report/${month}/4`}
          title="Stool and Urine"
          subtitle="Step #4"
          status={
            !report || !report.stool
              ? 'unstarted'
              : report.stool.stool.complete && report.stool.urine.complete
              ? 'complete'
              : 'inProgress'
          }
        />
        <DashboardItem
          to={`/report/${month}/5`}
          title="Vital Stats"
          subtitle="Step #5"
          status={
            !report?.vitalStats
              ? 'unstarted'
              : !report.vitalStats.weight
              ? 'inProgress'
              : 'complete'
          }
        />
        <DashboardItem
          to={`/report/${month}/6`}
          title="Journal - Clinical Observations"
          subtitle="Step #6"
          status={!report?.clinicalObservations ? 'unstarted' : 'complete'}
        />
        <DashboardItem
          to={`/report/${month}/7`}
          title="Diet"
          subtitle="Step #7"
          status={dietStatus}
        />
        <DashboardItem
          to={`/report/${month}/8`}
          title="Supplements"
          subtitle="Step #8"
          status={!report?.supplements ? 'unstarted' : 'complete'}
        />
        <DashboardItem
          to={`/report/${month}/9`}
          title="Medications"
          subtitle="Step #9"
          status={!report?.nonSupplementMedications ? 'unstarted' : 'complete'}
        />
        <DashboardItem
          to={`/report/${month}/10`}
          title="Physical Strategies"
          subtitle="Step #10"
          status={!report?.physicalStrategies ? 'unstarted' : 'complete'}
        />
        <DashboardItem
          to={`/report/${month}/11`}
          title="Mind Strategies"
          subtitle="Step #11"
          status={!report?.mentalStrategies ? 'unstarted' : 'complete'}
        />
        <DashboardItem
          to={`/report/${month}/12`}
          title={`${stagesCount} Steps To Healing`}
          subtitle="Step #12"
          status={!report?.stages ? 'unstarted' : 'complete'}
        />
        <DashboardItem
          to={`/report/${month}/13`}
          title="Support Team"
          subtitle="Step #13"
          status={!report?.support ? 'unstarted' : 'complete'}
        />
      </SimpleGrid>
    </Stack>
  )
}
