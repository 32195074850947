// src/components/MacroQuestions.tsx
import { Heading, Stack, Text, Tooltip } from '@chakra-ui/react'
import { QuestionIcon } from '@chakra-ui/icons'
import useCurrentReport from '../../hooks/useCurrentReport'
import useUpdateReportDoc from '../../hooks/useUpdateReportDoc'
import ValidatedInput from '../../components/ValidatedInput'

const MacroQuestions = () => {
  const updateReport = useUpdateReportDoc()
  const report = useCurrentReport()

  const handleInputChange = (field: string, value: number | null) => {
    updateReport({ macros: { ...report?.macros, [field]: value } })
  }

  return (
    <Stack>
      <Heading size="md">Macro Questions</Heading>
      <Text>
        We ask that you enter all food and beverages for ONE typical day{' '}
        <Tooltip label="Net Carbohydrates = Total grams Carbohydrates minus grams fiber. To calculate: Carb Manager App, My fitness Pal, Chronometer or other free App.">
          <QuestionIcon color="gray.400" />
        </Tooltip>
      </Text>
      <Text fontStyle="italic">On a typical day this month I ate:</Text>

      <ValidatedInput
        label="Grams of Protein"
        value={report?.macros?.protienGrams ?? null} // Use nullish coalescing operator
        min={1}
        max={300}
        isRequired={true}
        onChange={(value) => handleInputChange('protienGrams', value)}
      />
      <ValidatedInput
        label="Grams of Fat"
        value={report?.macros?.fatGrams ?? null} // Use nullish coalescing operator
        min={1}
        max={300}
        isRequired={true}
        onChange={(value) => handleInputChange('fatGrams', value)}
      />
      <ValidatedInput
        label="Grams of Net Carbohydrates"
        value={report?.macros?.carbGrams ?? null} // Use nullish coalescing operator
        min={0}
        max={300}
        isRequired={true}
        onChange={(value) => handleInputChange('carbGrams', value)}
      />
    </Stack>
  )
}

export default MacroQuestions
