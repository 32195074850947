import { RouteObject } from 'react-router-dom'

import AddBloodWork from '../pages/AddBloodWork'
import ALSFRS from '../pages/ALSFRS'
import ALSSQ from '../pages/ALSSQ'
import BloodWork from '../pages/BloodWork'
import ClinicalObservations from '../pages/ClinicalObservations'
import Diets from '../pages/Diets'
import EditProfile from '../pages/EditProfile'
import Help from '../pages/Help'
import MentalStrategies from '../pages/MentalStrategies'
import NonSupMedicationsForm from '../pages/NonSupMedicationsForm'
import PANAS from '../pages/PANAS'
import PhysicalStrategies from '../pages/PhysicalStrategies'
import Print from '../pages/Print'
import ReportComplete from '../pages/ReportComplete'
import Stages from '../pages/Stages'
import Stool from '../pages/Stool'
import SupplementsForm from '../pages/SupplementsForm'
import Support from '../pages/Support'
import VitalStats from '../pages/VitalStats'

const userRoutes: RouteObject[] = [
  { path: '/profile', element: <EditProfile /> },
  { path: '/help', element: <Help /> },
  { path: '/report/:month/1', element: <ALSFRS /> },
  { path: '/report/:month/2', element: <ALSSQ /> },
  { path: '/report/:month/3', element: <PANAS /> },
  { path: '/report/:month/4', element: <Stool /> },
  { path: '/report/:month/5', element: <VitalStats /> },
  { path: '/report/:month/6', element: <ClinicalObservations /> },
  { path: '/report/:month/7', element: <Diets /> },
  { path: '/report/:month/8', element: <SupplementsForm /> },
  { path: '/report/:month/9', element: <NonSupMedicationsForm /> },
  { path: '/report/:month/10', element: <PhysicalStrategies /> },
  { path: '/report/:month/11', element: <MentalStrategies /> },
  { path: '/report/:month/12', element: <Stages /> },
  { path: '/report/:month/13', element: <Support /> },
  { path: '/report/:month/14', element: <ReportComplete /> },
  { path: '/report/:month/100', element: <ReportComplete /> },
  { path: '/labs', element: <BloodWork /> },
  { path: '/labs/create', element: <AddBloodWork /> },
  { path: '/print', element: <Print /> },
]

export default userRoutes
