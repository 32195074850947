import { Timestamp } from 'firebase/firestore'
import moment from 'moment'

export const formatDateFull = (timestamp: Timestamp) => {
  console.log(timestamp)
  const date = moment(timestamp.toDate())
  const day = date.date()
  const month = date.format('MMM')
  const year = date.year()

  const daySuffix = (day: number) => {
    if (day > 3 && day < 21) return 'th'
    switch (day % 10) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  }

  return `${day}${daySuffix(day)} ${month} ${year}`
}

export const formatDateMonth = (timestamp: Timestamp) => {
  const date = moment(timestamp.toDate())
  return date.format('MMM YYYY')
}
