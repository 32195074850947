import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Checkbox,
} from '@chakra-ui/react'
import { map } from 'lodash'
import { useReportingForParam } from '../../hooks/useReportingForMonth'
import useReports from '../../hooks/useReports'
import useStages from '../../hooks/useStages'
import { StageStatus } from '../../types/FirestoreUser'
import FirestoreUser from '../../types/FirestoreUser'

export const StagesTable = ({
  update,
  user,
}: {
  update?: (val: { id: string; status: StageStatus }) => void
  user: FirestoreUser
}) => {
  const stages = useStages()
  const reports = useReports()
  const reportingFor = useReportingForParam()
  const userStages = user?.stages || {}

  return (
    <TableContainer>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>Step</Th>
            <Th>Title</Th>
            <Th>Started</Th>
            <Th>Complete</Th>
            <Th>Completed Month</Th>
          </Tr>
        </Thead>
        <Tbody>
          {stages?.map((stage, index) => {
            const status = userStages[stage.id] || 'unstarted'
            let completedMonth: string | null = null
            let allowEdit = true

            if (status === 'complete') {
              map(reports, (report, month) => {
                if (report?.stageProgress?.[stage.id] === 'complete') {
                  completedMonth = month
                  if (reportingFor) {
                    allowEdit = month >= reportingFor
                  }
                }
              })
            }

            return (
              <Tr key={stage.id}>
                <Td>{index === 0 ? '--' : index}</Td>
                <Td>{stage.title}</Td>
                <Td>
                  {index > 0 && (
                    <Checkbox
                      disabled={!allowEdit || !update}
                      isChecked={status === 'started' || status === 'complete'}
                      onChange={(e) => {
                        if (update)
                          update({
                            id: stage.id,
                            status: e.target.checked ? 'started' : 'unstarted',
                          })
                      }}
                    />
                  )}
                </Td>
                <Td>
                  <Checkbox
                    disabled={!update || !allowEdit}
                    isChecked={status === 'complete'}
                    onChange={(e) => {
                      if (update)
                        update({
                          id: stage.id,
                          status: e.target.checked
                            ? 'complete'
                            : index > 0
                            ? 'started'
                            : 'unstarted',
                        })
                    }}
                  />
                </Td>
                <Td>{completedMonth || ''}</Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default StagesTable
