import moment from 'moment'

export default (date: string) => {
  const acceptedDateFormats = [
    'YYYY-MM-DD',
    'YYYY-M-D',
    'YYYY-MM-D',
    'YYYY-M-DD',
  ]
  const m = moment(date, acceptedDateFormats, true)
  return m.isValid()
}
