import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import { Button, HStack } from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom'

export default () => {
  const location = useLocation()
  const parts = location.pathname.split('/')
  const step = parseInt(parts[3])

  //TODO: Make step a prop?
  let previousUrl = `/report/${parts[2]}/${step - 1}`;
  let previousText = 'Previous';

  if (step === 1) {
    previousUrl = '/';
    previousText = 'Dashboard';
  }

  return (
    <HStack spacing={2}>
      <Link to={previousUrl}>
        <Button
          colorScheme='gray'
          size='sm'
          disabled={step === 1}
          variant='outline'
          leftIcon={<ArrowBackIcon />}
        >
          {previousText}
        </Button>
      </Link>
      <Button size='sm' type='submit' rightIcon={<ArrowForwardIcon />}>
        Yes, OK
      </Button>
    </HStack>
  )
}
