// ContentItem.tsx
import React from 'react'
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react'
import { ContentItem } from '../../../types/contentManagement'

interface ContentItemProps {
  content: ContentItem
  onEdit: (content: ContentItem) => void
  onDelete: (content: ContentItem) => void
}

const ContentItemComponent: React.FC<ContentItemProps> = ({
  content,
  onEdit,
  onDelete,
}) => (
  <Box key={content.id} borderWidth="1px" borderRadius="lg" p={4}>
    <Flex justify="space-between" align="center" wrap="wrap">
      <Box maxW="70%">
        <Heading size="sm">{content.id}</Heading>
        <Text mt={2}>
          {content.text.length > 50
            ? `${content.text.substring(0, 50)} ...`
            : content.text}
        </Text>
      </Box>
      <Box ml="auto">
        <Button
          size="sm"
          colorScheme="blue"
          mr={2}
          onClick={() => onEdit(content)}
        >
          Edit
        </Button>
        {/* <Button size="sm" colorScheme="red" onClick={() => onDelete(content)}>
          Delete
        </Button> */}
      </Box>
    </Flex>
  </Box>
)

export default ContentItemComponent
