import { Stack } from '@chakra-ui/react'
import MonthlyReportHeader from '../components/MonthlyReportHeader'
import useBrands from '../hooks/useBrands'
import useConfigList from '../hooks/useConfigList'
import useNavigateNext from '../hooks/useNavigateNext'
import Regimens from '../components/Regimens'
import usePurpleBubblesText from '../hooks/usePurpleBubblesText'
import PurpleBubbleContent from '../components/PurpleBubbleContent'
import useUpdateReportDoc from '../hooks/useUpdateReportDoc'

export default () => {
  const items = useConfigList('nonSupplementMedications')
  const brands = useBrands()
  const navNext = useNavigateNext()
  const updateReport = useUpdateReportDoc()
  const purpleBubbleContent = usePurpleBubblesText('Step-9')

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    updateReport({ nonSupplementMedications: true })
    navNext()
  }

  const lists = [
    {
      fieldId: 'nonSupplementMedicationId',
      label: 'Medication',
      items,
      id: 'nonSupplementMedications',
    },
    {
      fieldId: 'brandId',
      label: 'Brand',
      items: brands,
      id: 'brands',
    },
  ]

  return (
    <Stack spacing={4}>
      <form onSubmit={handleSubmit}>
        <MonthlyReportHeader title="Non-Supplement Medications" />
      </form>
      {purpleBubbleContent?.text && (
        <PurpleBubbleContent text={purpleBubbleContent.text} />
      )}
      <Regimens
        recordLabel="Medication"
        amountEnabled
        frequencyEnabled
        methodEnabled
        lists={lists}
        alert="Please include ALL medications that you are taking, even if they are not for ALS."
      />
    </Stack>
  )
}
