import { initializeApp } from 'firebase/app'
import {
  CollectionReference,
  collection,
  getFirestore
} from 'firebase/firestore'
import FirestoreUser from './types/FirestoreUser'

initializeApp({
  apiKey: 'AIzaSyARFpjOM8TU3cUUumAW56YsXOVSzr4hd0E',
  authDomain: 'healing-als.firebaseapp.com',
  projectId: 'healing-als',
  storageBucket: 'healing-als.appspot.com',
  messagingSenderId: '829487775848',
  appId: '1:829487775848:web:b18436750a0b8b19083bdd'
})

export const collections = {
  users: collection(
    getFirestore(),
    'users'
  ) as CollectionReference<FirestoreUser>
}
