import { Stack } from '@chakra-ui/react'
import MonthlyReportHeader from '../components/MonthlyReportHeader'
import useBrands from '../hooks/useBrands'
import useConfigList from '../hooks/useConfigList'
import Regimens from '../components/Regimens'
import useNavigateNext from '../hooks/useNavigateNext'
import usePurpleBubblesText from '../hooks/usePurpleBubblesText'
import PurpleBubbleContent from '../components/PurpleBubbleContent'
import useUpdateReportDoc from '../hooks/useUpdateReportDoc'

export default () => {
  const items = useConfigList('supplements')
  const brands = useBrands()
  const navNext = useNavigateNext()
  const updateReport = useUpdateReportDoc()
  const purpleBubbleContent = usePurpleBubblesText('Step-8')

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    updateReport({ supplements: true })
    navNext()
  }

  const lists = [
    {
      fieldId: 'supplementId',
      label: 'Supplement',
      items,
      id: 'supplements',
    },
    {
      fieldId: 'brandId',
      label: 'Brand',
      items: brands,
      id: 'brands',
    },
  ]

  return (
    <Stack spacing={4}>
      <form onSubmit={handleSubmit}>
        <MonthlyReportHeader title="Supplements" />
      </form>
      {purpleBubbleContent?.text && (
        <PurpleBubbleContent text={purpleBubbleContent.text} />
      )}
      <Regimens
        recordLabel="Supplement"
        amountEnabled
        frequencyEnabled
        methodEnabled
        lists={lists}
      />
    </Stack>
  )
}
