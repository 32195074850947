import { Container } from '@chakra-ui/react'
import AppRoutes from '../../routes/AppRoutes'
import useIsAdmin from '../../hooks/useIsAdmin'
import useHasAdvancedPermissions from '../../hooks/useHasAdvancedPermissions'
import useStore from '../../store/useStore'

const MainContent = () => {
  const user = useStore((state) => state.user) ?? null
  const isAdmin = useIsAdmin()
  const hasAdvancedPermissions = useHasAdvancedPermissions()

  return (
    <Container as="main" maxW="container.lg" flex="1" py={4}>
      <AppRoutes
        user={user}
        isAdmin={isAdmin}
        hasAdvancedPermissions={hasAdvancedPermissions}
      />
    </Container>
  )
}

export default MainContent
