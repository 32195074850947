// useAlertDialog.ts
import { useState, useRef } from 'react'
import { ContentItem } from '../../../types/contentManagement'

const useAlertDialog = () => {
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [itemToDelete, setItemToDelete] = useState<ContentItem | null>(null)
  const cancelRef = useRef<HTMLButtonElement>(null)

  const openAlertDialog = (content: ContentItem) => {
    setItemToDelete(content)
    setIsAlertOpen(true)
  }

  const closeAlertDialog = () => setIsAlertOpen(false)

  return {
    isAlertOpen,
    itemToDelete,
    cancelRef,
    openAlertDialog,
    closeAlertDialog,
  }
}

export default useAlertDialog
