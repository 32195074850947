import {
  CollectionReference,
  QueryDocumentSnapshot,
  collection,
  getFirestore,
  onSnapshot,
  query,
  where
} from 'firebase/firestore'
import { orderBy } from 'lodash'
import { useEffect, useState } from 'react'
import { Lab } from '../types/bloodWork'

const labsCollection = collection(
  getFirestore(),
  'labs'
) as CollectionReference<Lab>

export const useLabs = ({
  allowArchived
}: {
  allowArchived?: boolean
}) => {
  const [labs, setLabs] = useState<QueryDocumentSnapshot<Lab>[]>([])

  useEffect(() => {
    const unsub = onSnapshot(
      allowArchived
        ? labsCollection
        : query(labsCollection, where('archived', '==', false)),
      snap => {
        setLabs(orderBy(snap.docs, doc => doc.data().title, 'asc'))
      }
    )

    return () => unsub()
  }, [])

  return labs
}

export default labsCollection
