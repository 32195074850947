import {
  Heading,
  Stack,
  Text,
  Tooltip,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  RequiredIndicator,
  Box,
} from '@chakra-ui/react'
import { QuestionIcon } from '@chakra-ui/icons'
import useCurrentReport from '../../hooks/useCurrentReport'
import useUpdateReportDoc from '../../hooks/useUpdateReportDoc'
import { CarbStyleDiet } from '../../store/useStore'

const CarbDietQuestions = () => {
  const updateReport = useUpdateReportDoc()
  const report = useCurrentReport()

  const handleRadioChange = (value: CarbStyleDiet) => {
    updateReport({ carbStyleDiet: value })
  }

  return (
    <>
      <Heading size="md" mb={4}>
        Carb Dieting Questions
      </Heading>
      <FormControl as="fieldset" isRequired>
        <FormLabel as="legend" requiredIndicator={<></>}>
          <Box display="flex" alignItems="center">
            <Text>What best describes your diet for this month?</Text>
            <RequiredIndicator />
            <Tooltip label="Net Carbohydrates = Total grams Carbohydrates minus grams fiber. To calculate: Carb Manager App, My Fitness Pal, Cronometer, or other free app.">
              <QuestionIcon color="gray.400" />
            </Tooltip>
          </Box>
        </FormLabel>

        <RadioGroup onChange={handleRadioChange} value={report?.carbStyleDiet}>
          <Stack spacing={4}>
            <Radio value="carnivore">
              Carnivore: 0 carbohydrates includes Beef, Butter, Bacon, Eggs,
              Fish, Chicken, Pork, Wild game
            </Radio>
            <Radio value="carnivoreLion">
              Carnivore Lion: 0 carbohydrates - Lion diet includes Beef, Lamb,
              Wild game, No dairy
            </Radio>
            <Radio value="ketovore">
              Ketovore: &lt; 10 grams net carbohydrates per day, can include
              cream, butter, cheese, coconut oil, avocado, pickles, sauerkraut
              olives, sometimes a bit of fruit
            </Radio>
            <Radio value="ketogenic">
              Ketogenic: 11 to 25 grams net carbohydrates per day
            </Radio>
            <Radio value="limitedCarb">
              Limited Carbohydrate: 26 to 50 grams net carbohydrates per day
            </Radio>
            <Radio value="lowCarb">
              Low Carbohydrate: 51 to 75 grams carbohydrates per day
            </Radio>
            <Radio value="moderateCarb">
              Moderate Carbohydrate: 76 to 150 grams carbohydrates per day
            </Radio>
            <Radio value="unrestrictedCarb">
              Unrestricted Carbohydrate: &gt; 150 grams carbohydrates per day
            </Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
    </>
  )
}

export default CarbDietQuestions
