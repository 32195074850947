import React from 'react'
import { Collection } from '../../types/contentManagement'
import { Box, Heading, Select, Stack, Button } from '@chakra-ui/react'

interface CollectionsListProps {
  collections: Collection[]
  selectedCollection: Collection
  handleCollectionChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  setSelectedCollection: (collection: Collection) => void
  isMobile: boolean
}

interface MobileCollectionsMenuProps {
  collections: Collection[]
  selectedCollection: Collection
  handleCollectionChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

interface DesktopCollectionsMenuProps {
  collections: Collection[]
  selectedCollection: Collection
  setSelectedCollection: (collection: Collection) => void
}

const MobileCollectionsMenu: React.FC<MobileCollectionsMenuProps> = ({
  collections,
  selectedCollection,
  handleCollectionChange,
}) => (
  <Select
    mb={4}
    onChange={handleCollectionChange}
    value={selectedCollection.name}
  >
    {collections.map((collection) => (
      <option key={collection.name} value={collection.name}>
        {collection.name}
      </option>
    ))}
  </Select>
)

const DesktopCollectionsMenu: React.FC<DesktopCollectionsMenuProps> = ({
  collections,
  selectedCollection,
  setSelectedCollection,
}) => (
  <Stack spacing={2}>
    {collections.map((collection) => (
      <Button
        key={collection.name}
        variant="ghost"
        onClick={() => setSelectedCollection(collection)}
        isActive={selectedCollection.name === collection.name}
      >
        {collection.name}
      </Button>
    ))}
  </Stack>
)

const CollectionsMenu: React.FC<CollectionsListProps> = ({
  collections,
  selectedCollection,
  handleCollectionChange,
  setSelectedCollection,
  isMobile,
}) => {
  return (
    <Box
      w={{ base: '100%', md: '250px' }}
      p={4}
      borderRight={{ md: '1px solid #e2e8f0' }}
      mb={4}
    >
      <Heading size="md" mb={4}>
        Collections
      </Heading>
      {isMobile && (
        <MobileCollectionsMenu
          collections={collections}
          selectedCollection={selectedCollection}
          handleCollectionChange={handleCollectionChange}
        />
      )}
      {!isMobile && (
        <DesktopCollectionsMenu
          collections={collections}
          selectedCollection={selectedCollection}
          setSelectedCollection={setSelectedCollection}
        />
      )}
    </Box>
  )
}

export default CollectionsMenu
