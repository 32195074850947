import { useState } from 'react'
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Stack,
  Td,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { range, reverse } from 'lodash'
import moment from 'moment'

const TableCell = ({
  value,
  onUncheck,
  onCheck,
  editable,
  modalTitle,
  modalSubtitle,
}: {
  value: { month: number; year: number } | null
  onUncheck: () => void
  onCheck: (at: { month: number; year: number }) => void
  editable: boolean
  modalTitle: string
  modalSubtitle: string
}) => {
  const modal = useDisclosure()
  const [month, setMonth] = useState<number>()
  const [year, setYear] = useState<number>()
  const toast = useToast()

  const onClose = () => {
    setMonth(undefined)
    setYear(undefined)
    modal.onClose()
  }

  return (
    <Td>
      <HStack spacing={3}>
        <Checkbox
          isDisabled={!editable}
          isChecked={!!value}
          onChange={() => {
            if (!!value) {
              onUncheck()
            } else {
              modal.onOpen()
            }
          }}
        />
        {value ? (
          <Text>
            {moment.monthsShort()[value.month]} {value.year}
          </Text>
        ) : null}
      </HStack>
      <Modal isOpen={modal.isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalTitle}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Stack spacing={2}>
              <Text>{modalSubtitle}</Text>
              <SimpleGrid spacing={2} columns={[1, 2]}>
                <FormControl isRequired>
                  <FormLabel>Month</FormLabel>
                  <Select
                    value={value?.month.toString()}
                    onChange={(e) => {
                      setMonth(parseInt(e.target.value))
                    }}
                    placeholder="Choose Month"
                  >
                    {moment.months().map((title, index) => {
                      return (
                        <option value={index} key={index}>
                          {title}
                        </option>
                      )
                    })}
                  </Select>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Year</FormLabel>
                  <Select
                    value={value?.year.toString()}
                    onChange={(e) => {
                      setYear(parseInt(e.target.value))
                    }}
                    placeholder="Choose Year"
                  >
                    {reverse(
                      range(moment().year() - 4, moment().year() + 1)
                    ).map((year) => {
                      return (
                        <option value={year} key={year}>
                          {year}
                        </option>
                      )
                    })}
                  </Select>
                </FormControl>
              </SimpleGrid>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                if (month === undefined || year === undefined) {
                  return toast({
                    status: 'info',
                    title: 'Incomplete',
                    description: 'Please choose a year and a month',
                  })
                }
                if (year === moment().year() && month > moment().month()) {
                  return toast({
                    status: 'info',
                    title: 'Error',
                    description: 'Date is in the future',
                  })
                }

                onCheck({ month, year })
                onClose()
              }}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Td>
  )
}

export default TableCell
