import { ArrowDownIcon, ArrowUpIcon, DeleteIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Checkbox,
  Editable,
  EditableInput,
  EditablePreview,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getFirestore,
  setDoc,
  updateDoc
} from 'firebase/firestore'
import moment, { Moment } from 'moment'
import { useMemo, useRef, useState } from 'react'
import useStages from '../hooks/useStages'
import useStagesCount from '../hooks/useStagesCount'
import useStore from '../store/useStore'
import { Stage } from '../types/Stage'

export default () => {
  const stagesCount = useStagesCount()
  return (
    <Stack spacing={6}>
      <Heading>{`${stagesCount} Steps to Healing`}</Heading>
      <AdminLiveSessions />
      <Stages />
    </Stack>
  )
}

const AdminLiveSessions = () => {
  const omitSundays = useStore(state => state.meta?.omitSundays || [])

  const days = useMemo(() => {
    const moments: Moment[] = []

    const m = moment().startOf('month')

    for (let i = -50; i < 50; i++) {
      const m2 = m.clone().add(i, 'days')

      if ([0, 1, 4].includes(m2.weekday())) {
        moments.push(m2)
      }
    }

    return moments
  }, [])

  return (
    <Stack>
      <Text>Days Without Meetings</Text>
      <SimpleGrid spacing={2} columns={[1, 2, 4]}>
        {days.map(moment => {
          const date = moment.format('YYYY-MM-DD')
          const selected = omitSundays.includes(date)
          return (
            <Box key={date}>
              <Checkbox
                isChecked={selected}
                onChange={e => {
                  updateDoc(doc(getFirestore(), 'config/meta'), {
                    omitSundays: e.target.checked
                      ? arrayUnion(date)
                      : arrayRemove(date)
                  })
                }}
              >
                {moment.format('l (ddd)')}
              </Checkbox>
            </Box>
          )
        })}
      </SimpleGrid>
    </Stack>
  )
}

const Stages = () => {
  const stages = useStages()
  const modal = useDisclosure()
  const [title, setTitle] = useState('')
  const initialRef = useRef<any>()

  const onClose = () => {
    setTitle('')
    modal.onClose()
  }

  if (!stages) return null

  const coll = collection(getFirestore(), 'config')

  const updateStages = async (value: Stage[]) => {
    return setDoc(doc(coll, 'stages'), { value })
  }

  return (
    <>
      <Box>
        <Button onClick={modal.onOpen}>Add Step</Button>
      </Box>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>Title</Th>
              <Th>Move Up</Th>
              <Th>Move Down</Th>
              <Th>Delete</Th>
            </Tr>
          </Thead>
          <Tbody>
            {stages.map((stage, index) => {
              return (
                <StageItem
                  key={stage.id}
                  index={index}
                  title={stage.title}
                  onDelete={() => {
                    if (
                      window.confirm('Delete this step? ' + stage.title)
                    ) {
                      updateStages(stages.filter(s => s.id !== stage.id))
                    }
                  }}
                  onMoveDown={
                    index < stages.length - 1
                      ? () => {
                          const copy = [...stages]
                          copy[index + 1] = stage
                          copy[index] = stages[index + 1]
                          updateStages(copy)
                        }
                      : undefined
                  }
                  onMoveUp={
                    index > 0
                      ? () => {
                          const copy = [...stages]
                          copy[index - 1] = stage
                          copy[index] = stages[index - 1]
                          updateStages(copy)
                        }
                      : undefined
                  }
                  onEdit={title => {
                    const copy = [...stages]
                    copy[index] = {
                      ...copy[index],
                      title
                    }
                    updateStages(copy)
                  }}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Modal
        initialFocusRef={initialRef}
        isOpen={modal.isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Step</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Title</FormLabel>
              <Input
                ref={initialRef}
                placeholder='Title'
                value={title}
                onChange={e => setTitle(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                updateStages([
                  ...stages,
                  {
                    id: doc(coll).id,
                    title
                  }
                ])
                onClose()
              }}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

const StageItem = ({
  index,
  title,
  onMoveDown,
  onMoveUp,
  onDelete,
  onEdit
}: {
  index: number
  title: string
  onMoveUp?: () => void
  onMoveDown?: () => void
  onDelete: () => void
  onEdit: (title: string) => void
}) => {
  return (
    <Tr>
      <Td>{index === 0 ? '--' : index}</Td>
      <Td>
        <Editable defaultValue={title} onSubmit={onEdit}>
          <EditablePreview />
          <EditableInput />
        </Editable>
      </Td>
      <Td>
        <IconButton
          colorScheme='gray'
          aria-label='Move Up'
          icon={<ArrowUpIcon />}
          disabled={!onMoveUp}
          onClick={onMoveUp}
        />
      </Td>
      <Td>
        <IconButton
          colorScheme='gray'
          aria-label='Move Down'
          icon={<ArrowDownIcon />}
          disabled={!onMoveDown}
          onClick={onMoveDown}
        />
      </Td>
      <Td>
        <IconButton
          colorScheme='red'
          aria-label='Delete'
          icon={<DeleteIcon />}
          onClick={onDelete}
        />
      </Td>
    </Tr>
  )
}
