import moment from 'moment'
import { useParams } from 'react-router-dom'

const useReportDocId = () => {
  const { month } = useParams<{ month: string }>()

  if (!month)
    throw new Error(
      'Looking for reporting month but not available in params.'
    )

  return month
}

export const useLastMonthReportDocId = () => {
  const docId = useReportDocId()
  const m = moment(`${docId}-15`)
  return m.subtract(1, 'month').format('YYYY-MM')
}

export default useReportDocId
