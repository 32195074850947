import { Box, Button, Heading, Stack, useToast } from '@chakra-ui/react'
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import useHandleError from '../../hooks/useHandleError'
import useStore from '../../store/useStore'
import CourseConfigSection from './CourseConfigSection'
import ValidatedInput from '../../components/ValidatedInput'

export default () => {
  const meta = useStore((s) => s.meta)
  const [configState, setConfigState] = useState({
    dayOfMonthLimit: meta?.dayOfMonthLimit ?? null,
    numBootcamps: meta?.numBootcamps ?? null,
    bootcampNames: Array(meta?.numBootcamps || 3).fill(''),
    numEssentialsCourses: meta?.numEssentialsCourses ?? null,
    essentialsCoursesNames: Array(meta?.numEssentialsCourses || 7).fill(''),
  })
  const [isLoading, setIsLoading] = useState(false)
  const handleError = useHandleError()
  const toast = useToast()

  useEffect(() => {
    const fetchAllCoursesData = async () => {
      try {
        const docRef = doc(getFirestore(), 'config/meta')
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
          const courseData = docSnap.data()
          setConfigState((prevState) => ({
            ...prevState,
            bootcampNames: courseData.bootcampNames || [],
            essentialsCoursesNames: courseData.essentialsCoursesNames || [],
          }))
        }
      } catch (error) {
        handleError(error)
      }
    }

    fetchAllCoursesData()
  }, [handleError])

  useEffect(() => {
    if (meta) {
      setConfigState((prevState) => ({
        ...prevState,
        dayOfMonthLimit: meta.dayOfMonthLimit ?? null,
        numBootcamps: meta.numBootcamps ?? null,
        bootcampNames: meta.bootcampNames || [],
        numEssentialsCourses: meta.numEssentialsCourses ?? null,
        essentialsCoursesNames: meta.essentialsCoursesNames || [],
      }))
    }
  }, [meta])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      await updateDoc(doc(getFirestore(), 'config/meta'), {
        dayOfMonthLimit: configState.dayOfMonthLimit,
        numBootcamps: configState.numBootcamps,
        bootcampNames: configState.bootcampNames,
        numEssentialsCourses: configState.numEssentialsCourses,
        essentialsCoursesNames: configState.essentialsCoursesNames,
      })
      toast({
        title: 'Success',
        description: 'New configuration saved successfully.',
        status: 'success',
      })
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCourseNameChange = (
    type: 'bootcamp' | 'essential',
    index: number,
    value: string
  ) => {
    const key = type === 'bootcamp' ? 'bootcampNames' : 'essentialsCoursesNames'
    const newNames = [...configState[key]]
    newNames[index] = value
    setConfigState((prevState) => ({
      ...prevState,
      [key]: newNames,
    }))
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={6}>
        <Heading>Configuration</Heading>
        <ValidatedInput
          label="Day of month limit"
          min={0}
          max={31}
          inputWidth={'55%'}
          isRequired={true}
          value={configState.dayOfMonthLimit}
          onChange={(value) =>
            setConfigState((prevState) => ({
              ...prevState,
              dayOfMonthLimit: value,
            }))
          }
          inline={false}
        />
        <CourseConfigSection
          title="Essentials Courses"
          numberLabel="Number of Essentials Courses"
          value={configState.numEssentialsCourses}
          setNumberValue={(value) =>
            setConfigState((prevState) => ({
              ...prevState,
              numEssentialsCourses: value,
            }))
          }
          namesLabel="Essentials Courses Names"
          namesValue={configState.essentialsCoursesNames}
          handleNameChange={(index, value) =>
            handleCourseNameChange('essential', index, value)
          }
        />
        <CourseConfigSection
          title="Bootcamps"
          numberLabel="Number of Bootcamps"
          value={configState.numBootcamps}
          setNumberValue={(value) =>
            setConfigState((prevState) => ({
              ...prevState,
              numBootcamps: value,
            }))
          }
          namesLabel="Bootcamp Names"
          namesValue={configState.bootcampNames}
          handleNameChange={(index, value) =>
            handleCourseNameChange('bootcamp', index, value)
          }
        />
        <Box>
          <Button isDisabled={isLoading} isLoading={isLoading} type="submit">
            Save
          </Button>
        </Box>
      </Stack>
    </form>
  )
}
