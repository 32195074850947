import { useToast } from '@chakra-ui/react'
import { doc, setDoc, getFirestore } from 'firebase/firestore'
import { ContentItem, Collection } from '../../../types/contentManagement'

const useDeleteContent = (
  collection: Collection,
  collectionItems: ContentItem[],
  setCollectionItems: (items: ContentItem[]) => void,
  closeAlertDialog: () => void
) => {
  const toast = useToast()

  const handleDeleteContent = async (content: ContentItem) => {
    try {
      const newCollectionItems = collectionItems.filter(
        (item) => item.id !== content.id
      )
      setCollectionItems(newCollectionItems)
      await setDoc(doc(getFirestore(), 'content', collection.docName), {
        contentBlocks: newCollectionItems,
      })
      toast({
        title: 'Success',
        description: `The ${content.id} content block has been deleted`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    } catch (error) {
      toast({
        title: 'Error',
        description: 'An error occurred while deleting the content block.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } finally {
      closeAlertDialog()
    }
  }

  return {
    handleDeleteContent,
  }
}

export default useDeleteContent
