import { DeleteIcon } from '@chakra-ui/icons'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  Stack,
  Text
} from '@chakra-ui/react'
import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  QueryDocumentSnapshot,
  serverTimestamp,
  Timestamp
} from 'firebase/firestore'
import { useEffect, useMemo, useRef, useState } from 'react'
import useIsAdmin from '../hooks/useIsAdmin'

type HelpItem = {
  question: string
  answer: string
  createdAt: Timestamp
}

export default () => {
  const isAdmin = useIsAdmin()
  const [docs, setDocs] = useState<QueryDocumentSnapshot<HelpItem>[]>([])
  const [question, setQuestion] = useState('')
  const [answer, setAnswer] = useState('')
  const [deletingId, setDeletingId] = useState<string>()
  const cancelRef = useRef<any>()

  const col = useMemo(() => {
    return collection(
      getFirestore(),
      'help'
    ) as CollectionReference<HelpItem>
  }, [])

  useEffect(() => {
    const unsub = onSnapshot(
      query(col, orderBy('createdAt', 'asc')),
      snap => {
        setDocs(snap.docs)
      }
    )

    return () => unsub()
  }, [col])

  return (
    <Stack spacing={6}>
      <Heading>Help</Heading>
      {isAdmin && (
        <form
          onSubmit={e => {
            e.preventDefault()

            addDoc(col, { question, answer, createdAt: serverTimestamp() })
            setQuestion('')
            setAnswer('')
          }}
        >
          <Stack spacing={4}>
            <FormControl>
              <FormLabel>Question</FormLabel>
              <Input
                required
                value={question}
                onChange={e => setQuestion(e.target.value)}
                placeholder='Enter a question...'
              />
            </FormControl>
            <FormControl>
              <FormLabel>Answer</FormLabel>
              <Input
                required
                value={answer}
                onChange={e => setAnswer(e.target.value)}
                placeholder='Enter an answer...'
              />
            </FormControl>
            <Button type='submit'>Create</Button>
          </Stack>
        </form>
      )}
      {docs.map(doc => {
        const item = doc.data()

        return (
          <HStack justifyContent='space-between' key={doc.id}>
            <Stack spacing={1} key={doc.id}>
              <Heading size='md'>{item.question}</Heading>
              <Text>{item.answer}</Text>
            </Stack>
            {isAdmin && (
              <IconButton
                colorScheme='gray'
                aria-label='Delete'
                icon={<DeleteIcon />}
                onClick={() => setDeletingId(doc.id)}
              />
            )}
          </HStack>
        )
      })}
      <AlertDialog
        isOpen={!!deletingId}
        leastDestructiveRef={cancelRef}
        onClose={() => setDeletingId(undefined)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Confirm
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this item?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                colorScheme='gray'
                ref={cancelRef}
                onClick={() => setDeletingId(undefined)}
              >
                Cancel
              </Button>
              <Button
                colorScheme='red'
                onClick={() => {
                  deleteDoc(doc(col, deletingId))
                  setDeletingId(undefined)
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Stack>
  )
}
