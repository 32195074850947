import { AddIcon } from '@chakra-ui/icons'
import { Box, Button, Heading, SimpleGrid, Stack } from '@chakra-ui/react'
import moment from 'moment'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import DashboardItem from '../components/DashboardItem'

export default () => {
  const [pastBloodWork] = useState([
    {
      id: '1',
      data: () => {
        return {
          lab: 'Quest',
          panels: 3,
          date: new Date('2023-02-01')
        }
      }
    },
    {
      id: '2',
      data: () => {
        return {
          lab: 'LabCorp',
          panels: 5,
          date: new Date('2022-06-12')
        }
      }
    },
    {
      id: '3',
      data: () => {
        return {
          lab: 'LabCorp',
          panels: 2,
          date: new Date('2021-08-30')
        }
      }
    },
    {
      id: '4',
      data: () => {
        return {
          lab: 'Quest',
          panels: 1,
          date: new Date('2019-01-08')
        }
      }
    }
  ])
  return (
    <Stack spacing={5}>
      <Heading>Your Lab Tests Records</Heading>
      <Stack spacing={2}>
        <Box>
          <Link to='/labs/create'>
            <Button rightIcon={<AddIcon />}>Add Lab Test</Button>
          </Link>
        </Box>
        <SimpleGrid minChildWidth='300px' spacing={2}>
          {pastBloodWork.map(item => {
            const data = item.data()
            return (
              <DashboardItem
                title={moment(data.date).format('LL')}
                subtitle={`${data.lab}: ${data.panels} ${
                  data.panels === 1 ? 'panel' : 'panels'
                }`}
                to={`/labs/${item.id}`}
                status='bloodWork'
              />
            )
          })}
        </SimpleGrid>
      </Stack>
    </Stack>
  )
}
