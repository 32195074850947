import { useToast } from '@chakra-ui/react'
import { useCallback } from 'react'

const useHandleError = () => {
  const toast = useToast()

  const handleError = useCallback(
    (e: any) => {
      console.error(e)

      let description = 'Unknown error.'

      if (typeof e === 'string') {
        description = e
      } else if (typeof e.message === 'string') {
        description = e.message
      }

      toast({
        title: 'Error',
        description,
        status: 'error',
        isClosable: true,
      })
    },
    [toast] // Include 'toast' in the dependency array
  )

  return handleError
}

export default useHandleError
