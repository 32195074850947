import React, { useState } from 'react'
import CollectionsMenu from './CollectionsMenu'
import CollectionsContent from './CollectionsContent'
import { Collection } from '../../types/contentManagement'
import { collections } from './collectionsData'
import { Flex, useBreakpointValue } from '@chakra-ui/react'

const ContentManagement = () => {
  const [selectedCollection, setSelectedCollection] = useState<Collection>(
    collections[0]
  )
  const isMobile = useBreakpointValue({ base: true, md: false }) ?? false

  const handleCollectionChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const collection =
      collections.find((col) => col.name === event.target.value) ||
      collections[0]
    setSelectedCollection(collection)
  }

  return (
    <Flex direction={{ base: 'column', md: 'row' }} p={4} h="82vh">
      <CollectionsMenu
        collections={collections}
        selectedCollection={selectedCollection}
        handleCollectionChange={handleCollectionChange}
        setSelectedCollection={setSelectedCollection}
        isMobile={isMobile}
      />
      <CollectionsContent collection={selectedCollection} />
    </Flex>
  )
}

export default ContentManagement
