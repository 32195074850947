import {
  Box,
  FormControl,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Text,
} from '@chakra-ui/react'
import { keys, map, sum, values } from 'lodash'
import { useCallback, useMemo } from 'react'
import MonthlyReportHeader from '../components/MonthlyReportHeader'
import MonthlyReportNavButtons from '../components/MonthlyReportNavButtons'
import useCurrentReport from '../hooks/useCurrentReport'
import useNavigateNext from '../hooks/useNavigateNext'
import useUpdateReportDoc from '../hooks/useUpdateReportDoc'
import { MonthlyReportQuestionnaire } from '../store/useStore'
import Questionnaire, { Answer, Question } from '../types/Questionnaire'
import usePurpleBubblesText from '../hooks/usePurpleBubblesText'
import PurpleBubbleContent from '../components/PurpleBubbleContent'

export default ({ questionnaire }: { questionnaire: Questionnaire }) => {
  const currentReport = useCurrentReport()
  const updateReport = useUpdateReportDoc()
  const navNext = useNavigateNext()
  const questionIds = useMemo(() => {
    return keys(questionnaire.questions)
  }, [questionnaire])
  let purpleBubbleContent
  if (questionnaire.key === 'alsfrs') {
    purpleBubbleContent = usePurpleBubblesText('Step-1')
  } else if (questionnaire.key === 'alssq') {
    purpleBubbleContent = usePurpleBubblesText('Step-2')
  }

  const answers = useMemo(() => {
    return currentReport?.[questionnaire.key]?.answers || {}
  }, [currentReport, questionnaire])

  const maxScore = useMemo(() => {
    return sum(
      values(questionnaire.questions).map((q) => q.options[0][0].points)
    )
  }, [questionnaire])

  const calculateScore = useCallback(
    (answers: Record<string, Answer>) => {
      let score = 0
      map(answers, (answer, questionId) => {
        if (answer.option === undefined) return

        score +=
          questionnaire.questions[questionId].options[answer.set || 0][
            answer.option
          ].points
      })
      return score
    },
    [questionnaire]
  )

  const currentScore = useMemo(() => {
    return calculateScore(answers)
  }, [answers, calculateScore])

  const Score = () => {
    return (
      <Box>
        <Stat>
          <StatLabel>Score</StatLabel>
          <StatNumber>
            {currentScore}/{maxScore}
          </StatNumber>
        </Stat>
      </Box>
    )
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        navNext()
      }}
    >
      <Stack spacing={6}>
        <MonthlyReportHeader title={questionnaire.subtitle} />
        {purpleBubbleContent?.text && (
          <PurpleBubbleContent text={purpleBubbleContent.text} />
        )}
        <Score />
        {questionIds.map((questionId, index) => {
          return (
            <QuestionItem
              key={questionId}
              question={questionnaire.questions[questionId]}
              answer={answers[questionId]}
              onChange={(answer) => {
                const copy = { ...answers }
                copy[questionId] = answer
                const data: MonthlyReportQuestionnaire = {
                  answers: copy,
                }

                if (
                  keys(questionnaire.questions).every(
                    (questionId) => copy[questionId]?.option !== undefined
                  )
                ) {
                  data.score = calculateScore(copy)
                }

                updateReport({
                  [questionnaire.key]: data,
                })
              }}
              index={index}
            />
          )
        })}
        <Score />
        <MonthlyReportNavButtons />
      </Stack>
    </form>
  )
}

const QuestionItem = ({
  question,
  answer,
  onChange,
  index,
}: {
  question: Question
  answer?: Answer
  onChange: (answer: Answer) => void
  index: number
}) => {
  const setIndex = answer?.set

  return (
    <Stack spacing={5}>
      {!!question.switch && (
        <FormControl isRequired>
          <Stack>
            <Heading size="md">
              <Text fontWeight="normal" as="span">
                {index + 1}.
              </Text>{' '}
              {question.switch}
            </Heading>
            <RadioGroup
              value={setIndex !== undefined ? setIndex.toString() : undefined}
              onChange={(value) => {
                const data: Answer = {
                  set: value === '0' ? 0 : 1,
                }
                if (answer?.option !== undefined) {
                  data.option = answer.option
                }
                onChange(data)
              }}
            >
              <Stack>
                <Radio value="0">Yes</Radio>
                <Radio value="1">No</Radio>
              </Stack>
            </RadioGroup>
          </Stack>
        </FormControl>
      )}
      {(question.options.length === 1 || setIndex !== undefined) && (
        <FormControl>
          <Stack>
            <Heading size="md">
              <Text fontWeight="normal" as="span">
                {index + 1}
                {question.switch ? (setIndex === 0 ? 'a' : 'b') : ''}.
              </Text>{' '}
              {question.title}
            </Heading>

            <RadioGroup
              value={answer?.option?.toString()}
              onChange={(value) => {
                const data: Answer = { option: parseInt(value) }
                if (setIndex !== undefined) {
                  data.set = setIndex
                }
                onChange(data)
              }}
            >
              <Stack>
                {question.options[setIndex || 0].map((option, index) => {
                  return (
                    <Radio key={index} value={index.toString()}>
                      {option.text}
                    </Radio>
                  )
                })}
              </Stack>
            </RadioGroup>
          </Stack>
        </FormControl>
      )}
    </Stack>
  )
}
