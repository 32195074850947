import { Box } from '@chakra-ui/react'

import useStore from '../../store/useStore'
import Toolbar from '../Toolbar'

const Header = () => {
  const user = useStore((state) => state.user)

  return <Box as="header">{user && <Toolbar />}</Box>
}

export default Header
