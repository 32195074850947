import { QuestionIcon } from '@chakra-ui/icons'
import {
  FormLabel,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger
} from '@chakra-ui/react'

export default ({
  url,
  title,
  description
}: {
  url?: string | string[]
  title: string
  description?: string
}) => {
  const urls = !url ? undefined : Array.isArray(url) ? url : [url]
  return (
    <Popover>
      <PopoverTrigger>
        <FormLabel>
          {title} <QuestionIcon color='gray.400' />
        </FormLabel>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>{title}</PopoverHeader>
        {description && <PopoverBody>{description}</PopoverBody>}
        {urls &&
          urls.map((url, index) => {
            return (
              <PopoverFooter key={`url-${index}`}>
                <Link href={url} color='purple.500' isExternal>
                  Product Link #{index + 1}
                </Link>
              </PopoverFooter>
            )
          })}
      </PopoverContent>
    </Popover>
  )
}
