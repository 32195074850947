import useIsAdmin from './useIsAdmin'
import useIsRole from './useIsRole'
import useIsSuperAdmin from './useIsSuperAdmin'

export default () => {
  const isAdmin = useIsAdmin()
  const isSuperAdmin = useIsSuperAdmin()
  const isResearcher = useIsRole('researcher')

  return isAdmin || isSuperAdmin || isResearcher
}
