import {
  Alert,
  AlertDescription,
  Box,
  Button,
  HStack,
  Heading,
  Radio,
  Stack,
  StackDivider,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { useState, useEffect, useCallback } from 'react'
import { chain, keys, map } from 'lodash'
import MonthlyReportHeader from '../components/MonthlyReportHeader'
import MonthlyReportNavButtons from '../components/MonthlyReportNavButtons'
import useCurrentReport, { useLastMonthReport } from '../hooks/useCurrentReport'
import useNavigateNext from '../hooks/useNavigateNext'
import useUpdateReportDoc from '../hooks/useUpdateReportDoc'
import ValidatedInput from '../components/ValidatedInput'
import { keepNumberInRange } from '../utils/dataValidation'
import usePurpleBubblesText from '../hooks/usePurpleBubblesText'
import PurpleBubbleContent from '../components/PurpleBubbleContent'

type Answers = { [row: string]: number }
const ALLOW_COPY_FROM_LAST_MONTH = false

export default () => {
  const toast = useToast()
  const updateReport = useUpdateReportDoc()
  const currentReport = useCurrentReport()
  const [partATotalScore, setPartATotalScore] = useState(0)
  const [partBTotalScore, setPartBTotalScore] = useState(0)
  const [basic, setBasic] = useState<BasicReport>(basicDefault)
  const purpleBubbleContent = usePurpleBubblesText('Step-3')

  useEffect(() => {
    if (currentReport?.panas?.basic) {
      setBasic(currentReport.panas.basic)
    }
  }, [currentReport])

  const report = {
    main: {
      answers: {},
      positive: 0,
      negative: 0,
      net: 0,
      complete: false,
    },
    supplemental: {
      answers: {},
      positive: 0,
      negative: 0,
      net: 0,
      complete: false,
    },
    modifiedPanas: {
      answers: {},
      positive: 0,
      negative: 0,
      net: 0,
      complete: false,
    },
    basic,
    ...currentReport?.panas,
  }

  const navNext = useNavigateNext()
  const modifiedPanasAnswers = report.modifiedPanas.answers
  const lastMonthReport = useLastMonthReport()

  const update = async (partial: Partial<PANASReport>) => {
    updateReport({
      panas: {
        ...report,
        ...partial,
      },
    })
  }

  const setAnswersCallback = (key: keyof PANASReport) => {
    return (answers: Answers) => {
      let positive = 0
      let negative = 0

      map(answers, (value, row) => {
        if (row[row.length - 1] === '+') {
          positive += value
        } else {
          negative += value
        }
      })

      update({
        [key]: {
          answers,
          positive,
          negative,
          net: positive - negative,
          complete:
            keys(answers).length === (key === 'main' ? part1 : part2).length,
        },
      })
    }
  }

  const lastMonthPanas = lastMonthReport?.panas

  // Function to calculate the total score for Part A
  const calculatePartATotalScore = useCallback(() => {
    const {
      positiveThoughts,
      fearOrWorry,
      forgiveOthers,
      forgiveSelf,
      releaseTrauma,
    } = basic
    return (
      Number(positiveThoughts) -
      Number(fearOrWorry) +
      Number(forgiveOthers) +
      Number(forgiveSelf) +
      Number(releaseTrauma)
    )
  }, [basic])

  // Function to calculate the total score for Part B
  const calculatePartBTotalScore = useCallback(() => {
    let totalScore = 0
    const keysToCheck = [
      'wantToBeWell',
      'believeCanGetWell',
      'safeToGetWell',
      'safeForOthersGetWell',
      'readyToGetWell',
      'willingToGetWell',
      'ableToGetWell',
      'deserveToGetWell',
      'takingAction',
    ]

    for (const field of keysToCheck) {
      const key = field as keyof BasicReport
      totalScore += basic[key] === 'yes' ? 2 : basic[key] === 'maybe' ? 1 : 0
    }

    return totalScore
  }, [basic])

  // Update the total score whenever the 'basic' state changes
  useEffect(() => {
    setPartATotalScore(calculatePartATotalScore())
    setPartBTotalScore(calculatePartBTotalScore())
  }, [basic, calculatePartATotalScore, calculatePartBTotalScore])

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        //TODO: Validate Parts B and C
        if (isPANASReportComplete(report)) {
          navNext()
        } else {
          toast({
            status: 'info',
            title: 'Incomplete',
            description: 'Please complete all parts',
          })
        }
      }}
    >
      <Stack spacing={6}>
        <MonthlyReportHeader title="Tell us about your mindset." />
        {ALLOW_COPY_FROM_LAST_MONTH &&
          lastMonthPanas?.main.complete &&
          lastMonthPanas.supplemental.complete &&
          !lastMonthPanas.copiedFromLastMonth && (
            <Alert status="info">
              <Stack>
                <AlertDescription>
                  Since you fully completed PANAS in your previous report, you
                  have the option to skip it for this month. This will copy all
                  of your answers from the previous month.
                </AlertDescription>
                <Box>
                  <Button
                    variant="link"
                    onClick={() => {
                      if (
                        window.confirm(
                          'Copy all your answers from the previous month?'
                        )
                      ) {
                        update({
                          ...lastMonthPanas,
                          copiedFromLastMonth: true,
                        })
                        navNext()
                      }
                    }}
                  >
                    Copy from Previous Month
                  </Button>
                </Box>
              </Stack>
            </Alert>
          )}
        <Stack>
          <Stack spacing={1}>
            {purpleBubbleContent?.text && (
              <PurpleBubbleContent text={purpleBubbleContent.text} />
            )}
            <HStack spacing={3} divider={<StackDivider />}>
              <Heading fontSize="xl">Part A</Heading>
              <Box>
                <Stat>
                  <StatNumber>{partATotalScore}/400</StatNumber>
                </Stat>
              </Box>
            </HStack>
            <ValidatedInput
              label="What percentage of the day are you thinking positive thoughts? (0-100%)"
              value={keepNumberInRange(report.basic.positiveThoughts)}
              min={0}
              max={100}
              inputWidth="30%"
              onChange={(value) => {
                const stringValue = value !== null ? value.toString() : ''
                update({
                  basic: { ...report.basic, positiveThoughts: stringValue },
                })
              }}
            />
            <ValidatedInput
              label="What percentage of the day are you in a state of stress, fear, worry, or anxiety? (0-100%)"
              value={keepNumberInRange(report.basic.fearOrWorry)}
              min={0}
              max={100}
              inputWidth="30%"
              onChange={(value) => {
                const stringValue = value !== null ? value.toString() : ''
                update({
                  basic: { ...report.basic, fearOrWorry: stringValue },
                })
              }}
            />
            <ValidatedInput
              label="What percentage have you forgiven other people and situations?  (0-100%)"
              value={keepNumberInRange(report.basic.forgiveOthers)}
              min={0}
              max={100}
              inputWidth="30%"
              onChange={(value) => {
                const stringValue = value !== null ? value.toString() : ''
                update({
                  basic: { ...report.basic, forgiveOthers: stringValue },
                })
              }}
            />
            <ValidatedInput
              label="What percentage have you forgiven yourself for anything, current or past? (0-100%)"
              value={keepNumberInRange(report.basic.forgiveSelf)}
              min={0}
              max={100}
              inputWidth="30%"
              onChange={(value) => {
                const stringValue = value !== null ? value.toString() : ''
                update({
                  basic: { ...report.basic, forgiveSelf: stringValue },
                })
              }}
            />
            <ValidatedInput
              label="What percentage of past trauma have you released? (0-100%)"
              value={keepNumberInRange(report.basic.releaseTrauma)}
              min={0}
              max={100}
              inputWidth="30%"
              onChange={(value) => {
                const stringValue = value !== null ? value.toString() : ''
                update({
                  basic: { ...report.basic, releaseTrauma: stringValue },
                })
              }}
            />
          </Stack>
        </Stack>
        <Stack>
          <HStack spacing={3} divider={<StackDivider />}>
            <Heading fontSize="xl">Part B</Heading>
            <Box>
              <Stat>
                <StatNumber>{partBTotalScore}/18</StatNumber>
              </Stat>
            </Box>
          </HStack>
          <TableContainer>
            <Table size="sm" variant="unstyled">
              <Thead>
                <Tr>
                  <Th></Th>
                  {map(panasBasicOptions, (label, key) => {
                    if (key === 'blank') return
                    return (
                      <Th textAlign="center" key={key}>
                        {label}
                      </Th>
                    )
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {basicSelectQuestions.map((q, index) => {
                  return (
                    <BasicTableRow
                      key={q.name}
                      index={index}
                      name={q.name}
                      text={q.question}
                      value={basic[q.name]}
                      onChange={(value) => {
                        const updatedBasic = {
                          ...basic,
                          [q.name]: value,
                        }
                        setBasic(updatedBasic)
                        update({
                          basic: updatedBasic,
                        })
                      }}
                    />
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Stack>
          <Heading fontSize="xl">Part C</Heading>
          <HStack spacing={3} divider={<StackDivider />}>
            <Box>
              <Stat>
                <StatLabel>Positive Affect Score</StatLabel>
                <StatNumber>{report.modifiedPanas.positive}</StatNumber>
              </Stat>
            </Box>
            <Box>
              <Stat>
                <StatLabel>Negative Affect Score</StatLabel>
                <StatNumber>{report.modifiedPanas.negative}</StatNumber>
              </Stat>
            </Box>
          </HStack>
          <Section
            items={modifiedPanas}
            answers={modifiedPanasAnswers}
            setAnswers={setAnswersCallback('modifiedPanas')}
            title="Modified Panas"
          />
        </Stack>
        {/* <Section
          items={part1}
          answers={answers}
          setAnswers={setAnswersCallback('main')}
          title='PANAS-SF'
        />
        <Section
          items={part2}
          answers={answers2}
          setAnswers={setAnswersCallback('supplemental')}
          title='Supplemental'
        /> */}
        <MonthlyReportNavButtons />
      </Stack>
    </form>
  )
}

const Section = ({
  title,
  items,
  answers,
  setAnswers,
}: {
  title: string
  items: string[]
  answers: Answers
  setAnswers: (answers: Answers) => void
}) => {
  return (
    <TableContainer>
      <Table size="sm" variant="unstyled">
        <Thead>
          <Tr>
            <Th>{title}</Th>
            {modifiedPanasQuestionOptions.map((option) => {
              return (
                <Th textAlign="center" key={option.label}>
                  {option.label}
                </Th>
              )
            })}
          </Tr>
        </Thead>
        <Tbody>
          {items.map((row, index) => {
            return (
              <Tr
                key={row}
                backgroundColor={index % 2 === 0 ? 'purple.50' : 'white'}
              >
                <Td fontSize="lg">
                  {index + 1}. {row.substring(0, row.length - 1)}
                </Td>
                {modifiedPanasQuestionOptions.map((option, value) => {
                  return (
                    <Td
                      key={value}
                      cursor="pointer"
                      onClick={() => {
                        setAnswers({
                          ...answers,
                          [row]: option.score,
                        })
                      }}
                      _hover={{
                        background: 'gray.100',
                      }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Radio
                          key={row + value}
                          isChecked={answers[row] === option.score}
                        />
                      </Box>
                    </Td>
                  )
                })}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

const part1 = [
  'Interested+',
  'Distressed-',
  'Excited+',
  'Upset-',
  'Strong+',
  'Guilty-',
  'Scared-',
  'Hostile-',
  'Enthusiastic+',
  'Proud+',
  'Irritable-',
  'Alert+',
  'Ashamed-',
  'Inspired+',
  'Nervous-',
  'Determined+',
  'Attentive+',
  'Jittery-',
  'Active+',
  'Afraid-',
]

const part2 = `Belief in a higher power+
Anxious-
Follow my intuition+
Mindful+
Poor Me-
Self-Aware+
Suppress Emotions-
Confident+
Depressed-
Judge self and others-
Frustrated-
Embrace social support+
Unworthy-
Have strong reasons for living+
Fear of rejection or shame-
Hopeful+
Powerless-
Take control of my health+
Grateful+
Fear of disability or death-`.split('\n')

const modifiedPanas = [
  'Hopeful+',
  'Angry-',
  'Guilty-',
  'Follow my intuition+',
  'Determined+',
  'Worried/Stressed-',
  'Depressed-',
  'Grateful+',
  'Unworthy-',
  'Have strong reasons for living+',
  'Powerless/Frustrated-',
  'Take control of my health+',
  'Ashamed/Embarassed-',
  'Self-aware of thoughts & feelings+',
  'Feel like a victim/Why me?-',
  'Embrace Social Support+',
  'Fear of Disability or Death-',
  'Spiritual or belief in higher power+',
  'Judgmental of Self & Others-',
  'Enjoy Life+',
]

const modifiedPanasQuestionOptions = [
  { label: 'None of the time', score: 1 },
  { label: 'Sometimes', score: 2 },
  { label: 'Moderate', score: 3 },
  { label: 'Most of the time', score: 4 },
  { label: 'Always', score: 5 },
]
export const panasBasicOptions = {
  yes: 'Yes',
  maybe: 'Maybe',
  no: 'No',
}

const basicDefault: BasicReport = {
  ableToGetWell: '',
  believeCanGetWell: '',
  deserveToGetWell: '',
  forgiveOthers: '',
  forgiveSelf: '',
  positiveThoughts: '',
  readyToGetWell: '',
  releaseTrauma: '',
  safeForOthersGetWell: '',
  safeToGetWell: '',
  wantToBeWell: '',
  willingToGetWell: '',
  fearOrWorry: '',
  takingAction: '',
}

type BasicReport = {
  positiveThoughts: string
  forgiveOthers: string
  forgiveSelf: string
  releaseTrauma: string
  fearOrWorry: string
  wantToBeWell: keyof typeof panasBasicOptions | ''
  believeCanGetWell: keyof typeof panasBasicOptions | ''
  safeToGetWell: keyof typeof panasBasicOptions | ''
  safeForOthersGetWell: keyof typeof panasBasicOptions | ''
  readyToGetWell: keyof typeof panasBasicOptions | ''
  willingToGetWell: keyof typeof panasBasicOptions | ''
  ableToGetWell: keyof typeof panasBasicOptions | ''
  deserveToGetWell: keyof typeof panasBasicOptions | ''
  takingAction: keyof typeof panasBasicOptions | ''
}

export type PANASReport = {
  main: PANASSectionReport
  supplemental: PANASSectionReport
  basic?: BasicReport
  copiedFromLastMonth?: boolean
  modifiedPanas?: PANASSectionReport
}

const basicSelectQuestions: {
  name: keyof BasicReport
  question: string
}[] = [
  {
    name: 'wantToBeWell',
    question: 'I want to be well.',
  },
  {
    name: 'believeCanGetWell',
    question: 'I believe I can get well.',
  },
  {
    name: 'safeToGetWell',
    question: 'Is it safe for me to get well.',
  },
  {
    name: 'safeForOthersGetWell',
    question: 'It is safe for others that I get well.',
  },
  {
    name: 'readyToGetWell',
    question: 'I am ready to get well.',
  },
  {
    name: 'willingToGetWell',
    question: 'I am willing to get well.',
  },
  {
    name: 'ableToGetWell',
    question: 'I am able to get well.',
  },
  {
    name: 'deserveToGetWell',
    question: 'I deserve to get well.',
  },
  {
    name: 'takingAction',
    question: 'I am taking action to get well.',
  },
]

const BasicTableRow = ({
  name,
  text,
  index,
  onChange,
  value: _value,
}: {
  name: keyof BasicReport
  text: string
  index: number
  onChange: (value: string) => void
  value: string
}) => {
  return (
    <Tr backgroundColor={index % 2 === 0 ? 'purple.50' : 'white'}>
      <Td fontSize="lg">
        {index + 1}. {text}
      </Td>
      {map(panasBasicOptions, (label, key) => {
        return (
          <Td
            key={key}
            cursor="pointer"
            onClick={() => {
              onChange(key)
            }}
            _hover={{
              background: 'gray.100',
            }}
          >
            <Box display="flex" alignItems="center" justifyContent="center">
              <Radio key={name + key} isChecked={_value === key} />
            </Box>
          </Td>
        )
      })}
    </Tr>
  )
}

export type PANASSectionReport = {
  answers: Answers
  positive: number
  negative: number
  net: number
  complete: boolean
}

export function isPANASReportComplete(report: PANASReport) {
  const basicComplete =
    report.basic &&
    chain(report.basic)
      .map((value, key) => {
        return {
          value,
          key,
        }
      })
      .every((item) => {
        return item.value.length > 0
      })
      .value()
  return report.modifiedPanas?.complete && basicComplete
}
