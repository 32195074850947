export type AmountUnit = keyof typeof amountUnits

export const amountUnits = {
  g: 'g',
  mg: 'mg',
  mcg: 'mcg',
  oz: 'oz',
  flOz: 'fl. oz',
  ml: 'mL/cc',
  tsp: 'tsp',
  tbsp: 'tbsp',
  iu: 'IU',
  drops: 'Drop(s)',
  fullDroppers: 'Full Dropper(s)',
  packets: 'Packet(s)',
  scoops: 'Scoop(s)',
  cfu: 'CFU',
  multivitamin: 'Multi-vitamin/mineral',
  unit: 'Unit'
}

export const methods = {
  diffuser: 'Diffuser',
  intradermal: 'Injection - Intradermal',
  intramuscular: 'Injection - Intramuscular',
  subcutaneous: 'Injection - Subcutaneous',
  lumbarPuncture: 'Injection - Lumbar Puncture',
  iv: 'IV Intravenous',
  nasalSpray: 'Nasal Spray',
  nebulize: 'Nebulize',
  chewable: 'Oral - Chewable',
  capsule: 'Oral - Capsule',
  liquid: 'Oral - Liquid',
  powder: 'Oral - Powder',
  tablet: 'Oral - Tablet',
  spray: 'Oral - Spray',
  oralSublingual: 'Oral - Sublingual',
  patch: 'Patch',
  suppository: 'Suppository',
  tincture: 'Oral - Tincture',
  transdermal: 'Transdermal',
  transdermalFeet: 'Transdermal (Feet)',
  feedingTube: 'Via Feeding Tube',
  implant: 'Implant',
  pellets: 'Oral - Pellets'
}

export type Method = keyof typeof methods

export type FrequencyUnit = keyof typeof frequencyUnits

export const frequencyUnits = {
  day: 'Day',
  week: 'Week',
  month: 'Month',
  year: 'Year'
}

export const durationUnits = {
  minutes: 'Minutes',
  hours: 'Hours',
  scans: 'Scans'
}

export type DurationUnit = keyof typeof durationUnits

export type Regimen = {
  id: string
  startDate: string
  endDate?: string
  list: string
  primaryId: string
  usages: Usage[]
  uid: string
}

export type Usage = {
  fields: SelectedFields
  frequency?: number
  frequencyUnit?: FrequencyUnit
  amount?: number
  amountUnit?: AmountUnit
  method?: Method
  duration?: number
  durationUnit?: DurationUnit
  startDate: string
  percentage?: Percentage
}

export const percentages = {
  low: '30%-60%',
  medium: '61%-90%',
  high: '91%-100%'
}

export type SelectedFields = {
  [fieldId: string]: string
}

export type Percentage = keyof typeof percentages
