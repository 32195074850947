import { Box, ChakraProvider } from '@chakra-ui/react'
import '@fontsource/karla/400-italic.css'
import '@fontsource/karla/400.css'
import '@fontsource/karla/700.css'
import { QueryClient, QueryClientProvider } from 'react-query'
import useAuth from './hooks/useAuth'
import useFetchConfig from './hooks/useFetchConfig'
import useFetchContent from './hooks/useFetchContent'
import useFetchDoctors from './hooks/useFetchDoctors'
import useFetchUserData from './hooks/useFetchUserData'
import useStore from './store/useStore'
import { theme } from './theme'
import Footer from './components/layout/Footer'
import Header from './components/layout/Header'
import MainContent from './components/layout/MainContent'

const client = new QueryClient()

export default () => {
  const user = useStore((state) => state.user)

  useAuth()
  useFetchDoctors()
  useFetchUserData()
  useFetchConfig()
  useFetchContent()

  if (user === undefined) return null

  return (
    <QueryClientProvider client={client}>
      <ChakraProvider theme={theme}>
        <Box display="flex" flexDirection="column" minHeight="100vh">
          <Header />
          <MainContent />
          <Footer />
        </Box>
      </ChakraProvider>
    </QueryClientProvider>
  )
}
