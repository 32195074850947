import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr
} from '@chakra-ui/react'
import { MultiSelect, Option } from 'chakra-multiselect'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { chain, map } from 'lodash'
import moment from 'moment'
import { Fragment, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import { FaCheck, FaPaperclip } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import CountrySelect from '../components/CountrySelect'
import useHandleError from '../hooks/useHandleError'
import { Diagnosis, diagnosisTitles } from '../types/FirestoreUser'

type ProgressionQueryParams = {
  minAge?: number
  maxAge?: number
  type: 'alsfrs' | 'alssq' | 'combined'
  direction: 'up' | 'down'
  minChange: number
  months: number
  country?: string
  gender?: string
  diagnosis?: Diagnosis[]
  monthsSinceDiagnosis?: number
}

type Hit = {
  change: number
  firstName: string
  lastName: string
  patientId: string
  uid: string
  scores: { month: string; score: number }[]
  diagnosis: Diagnosis
  diagnosisDate: string
  birthDate: string
  diagnosisDocumentation: string[]
  diagnosisVerified: boolean
}

// const dummy = [
//   {
//     uid: 'rXoLqU2YLKSqJmOZafdFFMV1OJb2',
//     patientId: '237810',
//     firstName: 'Patricia',
//     lastName: 'Tamowski',
//     birthDate: '1959-09-23',
//     diagnosisDate: '2022-08-01',
//     diagnosis: 'administrator',
//     change: 11,
//     scores: [
//       {
//         score: 37,
//         month: '2023-10'
//       },
//       {
//         score: 48,
//         month: '2024-01'
//       },
//       {
//         score: 47,
//         month: '2024-02'
//       }
//     ]
//   },
//   {
//     uid: 'EVfh72Juy0TtGWkYjNSuDtRA8in2',
//     patientId: '096298',
//     firstName: 'Jose',
//     lastName: 'Pinto',
//     birthDate: '1977-06-17',
//     diagnosisDate: '2020-12-20',
//     diagnosis: 'als',
//     change: 6,
//     scores: [
//       {
//         score: 17,
//         month: '2023-12'
//       },
//       {
//         score: 16,
//         month: '2024-01'
//       },
//       {
//         score: 22,
//         month: '2024-02'
//       }
//     ]
//   },
//   {
//     uid: 'Nf46mElLZeaTCIeOKM0gaVjCra23',
//     patientId: '343108',
//     firstName: 'Kathy',
//     lastName: 'Reno',
//     birthDate: '1970-10-12',
//     diagnosisDate: '2021-01-27',
//     diagnosis: 'als',
//     change: 6,
//     scores: [
//       {
//         score: 8,
//         month: '2023-10'
//       },
//       {
//         score: 14,
//         month: '2023-11'
//       }
//     ]
//   },
//   {
//     uid: 'gfb59a1LcDU4TVMNoa29F8fGW8j1',
//     patientId: '315151',
//     firstName: 'John',
//     lastName: 'Andic',
//     birthDate: '1987-05-15',
//     diagnosisDate: '2023-10-05',
//     diagnosis: 'als-probable',
//     change: 6,
//     scores: [
//       {
//         score: 40,
//         month: '2023-11'
//       },
//       {
//         score: 43,
//         month: '2023-12'
//       },
//       {
//         score: 45,
//         month: '2024-01'
//       },
//       {
//         score: 44,
//         month: '2024-02'
//       },
//       {
//         score: 46,
//         month: '2024-03'
//       }
//     ]
//   },
//   {
//     uid: 'ULhx1RbWUIdmLBHVsD2nHi3Vgqo1',
//     patientId: '109248',
//     firstName: 'Per',
//     lastName: 'Lynnerup',
//     birthDate: '1953-12-30',
//     diagnosisDate: '2023-02-15',
//     diagnosis: 'als',
//     change: 5,
//     scores: [
//       {
//         score: 36,
//         month: '2023-11'
//       },
//       {
//         score: 21,
//         month: '2024-02'
//       },
//       {
//         score: 26,
//         month: '2024-03'
//       },
//       {
//         score: 22,
//         month: '2024-04'
//       }
//     ]
//   },
//   {
//     uid: 'ROLypX41e2NfEhSxDQDTABZ3ESI2',
//     patientId: '987111',
//     firstName: 'Joseph',
//     lastName: 'Osowski',
//     birthDate: '1968-04-09',
//     diagnosisDate: '2021-11-09',
//     diagnosis: 'als',
//     change: 5,
//     scores: [
//       {
//         score: 31,
//         month: '2023-10'
//       },
//       {
//         score: 27,
//         month: '2023-11'
//       },
//       {
//         score: 25,
//         month: '2023-12'
//       },
//       {
//         score: 21,
//         month: '2024-01'
//       },
//       {
//         score: 26,
//         month: '2024-02'
//       },
//       {
//         score: 22,
//         month: '2024-03'
//       }
//     ]
//   }
// ]

export default () => {
  const [minAge, setMinAge] = useState('')
  const [maxAge, setMaxAge] = useState('')
  const [type, setType] = useState('alsfrs')
  const [direction, setDirection] = useState('up')
  const [minChange, setMinChange] = useState('0')
  const [months, setMonths] = useState('6')
  const [country, setCountry] = useState('')
  const [gender, setGender] = useState('')
  const [diagnosis, setDiagnosis] = useState<Option[]>([])
  const [monthsSinceDiagnosis, setMonthsSinceDiagnosis] = useState('')
  const [loading, setLoading] = useState(false)
  const handleError = useHandleError()
  const [hits, setHits] = useState<Hit[]>([])

  const displayMonths = useMemo(() => {
    return chain(hits)
      .map(user => user.scores)
      .flatten()
      .map(s => s.month)
      .uniq()
      .sort()
      .value()
  }, [hits])

  return (
    <Stack spacing={6}>
      <Heading>Progression Query</Heading>
      <form
        onSubmit={async e => {
          e.preventDefault()

          const params: ProgressionQueryParams = {
            direction: direction as any,
            maxAge: maxAge ? parseInt(maxAge) : undefined,
            minAge: minAge ? parseInt(minAge) : undefined,
            minChange: parseInt(minChange),
            months: parseInt(months),
            type: type as any,
            country: country || undefined,
            gender: gender || undefined,
            diagnosis:
              diagnosis.length > 0
                ? diagnosis.map(d => d.value as Diagnosis)
                : undefined,
            monthsSinceDiagnosis: monthsSinceDiagnosis
              ? parseInt(monthsSinceDiagnosis)
              : undefined
          }

          setLoading(true)
          try {
            console.log({ params })
            const progressionQuery = httpsCallable(
              getFunctions(),
              'progressionQuery'
            )
            const { data } = await progressionQuery(params)
            console.log(data)
            setHits(data as Hit[])
          } catch (e) {
            handleError(e)
          } finally {
            setLoading(false)
          }
        }}
      >
        <Stack spacing={4}>
          <FormControl isRequired>
            <FormLabel>Find me users whose following score...</FormLabel>
            <RadioGroup value={type} onChange={setType}>
              <HStack spacing={4}>
                <Radio value='alsfrs'>ALSFRS</Radio>
                <Radio value='alssq'>ALSSQ</Radio>
                <Radio value='combined'>Combined</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Has gone in the following direction...</FormLabel>
            <RadioGroup value={direction} onChange={setDirection}>
              <HStack spacing={4}>
                <Radio value='up'>Up</Radio>
                <Radio value='down'>Down</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>By at least...</FormLabel>
            <Input
              value={minChange}
              onChange={e => {
                setMinChange(e.target.value)
              }}
              type='number'
              placeholder='Minimum score change'
              step='1'
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Over the last X months...</FormLabel>
            <Input
              value={months}
              onChange={e => {
                setMonths(e.target.value)
              }}
              type='number'
              placeholder='# Months'
              step='1'
            />
          </FormControl>
          <SimpleGrid columns={[1, 2]} spacing={4}>
            <FormControl>
              <FormLabel>Min. Age</FormLabel>
              <Input
                type='number'
                step='1'
                value={minAge}
                onChange={e => {
                  setMinAge(e.target.value)
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Max. Age</FormLabel>
              <Input
                type='number'
                step='1'
                value={maxAge}
                onChange={e => {
                  setMaxAge(e.target.value)
                }}
              />
            </FormControl>
            <CountrySelect defaultValue={country} onChange={setCountry} />
            <FormControl>
              <FormLabel>Gender</FormLabel>
              <Select
                value={gender}
                placeholder='Choose Gender'
                onChange={e => {
                  setGender(e.target.value)
                }}
              >
                <option value='male'>Male</option>
                <option value='female'>Female</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Diagnosis</FormLabel>
              <MultiSelect
                options={map(diagnosisTitles, (label, value) => {
                  return {
                    label,
                    value
                  }
                })}
                value={diagnosis}
                placeholder='Choose Diagnosis'
                onChange={setDiagnosis as any}
              />
            </FormControl>
            <FormControl>
              <FormLabel># Months since Diagnosis</FormLabel>
              <Input
                type='number'
                step='1'
                value={monthsSinceDiagnosis}
                onChange={e => {
                  setMonthsSinceDiagnosis(e.target.value)
                }}
              />
            </FormControl>
          </SimpleGrid>
          <Box>
            <Button
              size='lg'
              type='submit'
              isLoading={loading}
              isDisabled={loading}
            >
              Submit
            </Button>
          </Box>
        </Stack>
      </form>
      {hits.length > 0 && (
        <>
          <Divider />
          <TableContainer>
            <Table size='sm'>
              <Thead>
                <Tr>
                  <Th>Patient ID</Th>
                  <Th>Diagnosis</Th>
                  <Td>Docs</Td>
                  <Th>Name</Th>
                  <Th>Change</Th>
                  <Th>Months</Th>
                </Tr>
              </Thead>
              <Tbody>
                {hits.map(user => {
                  return (
                    <Tr key={user.uid}>
                      <Td>
                        <Link
                          to={`/users/${user.uid}/print`}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <Button variant='link'>#{user.patientId}</Button>
                        </Link>
                      </Td>
                      <Td>
                        {diagnosisTitles[user.diagnosis]} (
                        {user.diagnosisDate})
                      </Td>
                      <Td>
                        <HStack spacing={1}>
                          {user.diagnosisDocumentation.length > 0 && (
                            <FaPaperclip size={20} color='black' />
                          )}
                          {user.diagnosisVerified && (
                            <FaCheck size={20} color='black' />
                          )}
                        </HStack>
                      </Td>
                      <Td>
                        {user.lastName}, {user.firstName}
                      </Td>
                      <Td>
                        {user.change > 0 ? '+' : ''}
                        {user.change}
                      </Td>
                      <Td display='flex'>
                        {displayMonths.map((month, index) => {
                          return (
                            <Fragment key={month}>
                              <Tooltip label={month}>
                                <Text cursor='pointer'>
                                  {user.scores.find(s => s.month === month)
                                    ?.score || '-'}
                                </Text>
                              </Tooltip>
                              {index < displayMonths.length - 1 && '/'}
                            </Fragment>
                          )
                        })}
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
          <Box>
            <Button variant='link'>
              <CSVLink
                data={hits.map(hit => {
                  const {
                    patientId,
                    change,
                    diagnosis,
                    diagnosisDate,
                    firstName,
                    lastName,
                    uid
                  } = hit
                  return {
                    'Patient ID': patientId,
                    'First Name': firstName,
                    'Last Name': lastName,
                    Change: change,
                    Diagnosis: diagnosisTitles[diagnosis],
                    'Diagnosis Date': moment(diagnosisDate).toISOString(),
                    UID: uid
                  }
                })}
                filename={`als-registry-query-${new Date().toISOString()}.csv`}
              >
                Download Table as CSV
              </CSVLink>
            </Button>
          </Box>
        </>
      )}
    </Stack>
  )
}
