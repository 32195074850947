import {
  CollectionReference,
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore'
import { useEffect } from 'react'
import useStore, { MonthlyReport } from '../store/useStore'
import { Regimen } from '../types/Regimen'
import userDoc from '../utils/userDoc'

const useFetchUserData = () => {
  const user = useStore((state) => state.user)

  useEffect(() => {
    if (!user) {
      return useStore.setState({
        firestoreUser: undefined,
        regimens: [],
        reports: {},
      })
    }

    const unsubRegimens = onSnapshot(
      query(
        collection(getFirestore(), 'regimens') as CollectionReference<
          Omit<Regimen, 'id'>
        >,
        where('uid', '==', user.uid)
      ),
      (snap) => {
        useStore.setState({
          regimens: snap.docs.map((doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            }
          }),
        })
      }
    )

    const unsubReports = onSnapshot(
      query(
        collection(
          getFirestore(),
          `users/${user.uid}/reports`
        ) as CollectionReference<MonthlyReport>
      ),
      (snap) => {
        const reports: Record<string, MonthlyReport> = {}
        snap.forEach((doc) => {
          reports[doc.id] = doc.data()
        })
        useStore.setState({ reports })
      }
    )

    const unsubUserDoc = onSnapshot(userDoc(user.uid), (snap) => {
      useStore.setState({ firestoreUser: snap.data() })
    })

    return () => {
      unsubUserDoc()
      unsubRegimens()
      unsubReports()
    }
  }, [user])
}

export default useFetchUserData
