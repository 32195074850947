import { Timestamp } from 'firebase/firestore'

export type Role = 'admin' | 'patient' | 'superadmin' | 'tester' | 'researcher'

export const roleTitles: Record<Role, string> = {
  admin: 'Admin',
  patient: 'User',
  superadmin: 'Super-Admin',
  tester: 'Tester',
  researcher: 'Researcher',
}

export const shortRoleTitles: Record<Role, string> = {
  admin: 'A',
  patient: 'U',
  superadmin: 'SA',
  tester: 'T',
  researcher: 'R',
}

export default interface FirestoreUser {
  email: string
  roles: Role[]
  createdAt: Timestamp
  unitSystem?: UnitSystem
  profile?: Profile
  patientId?: string
  stages?: {
    [stageId: string]: StageStatus
  }
  bootCamps?: Partial<
    Record<
      number,
      {
        startedAt: {
          month: number
          year: number
        }
        completedAt: {
          month: number
          year: number
        } | null
      } | null
    >
  >
  essentialsCourses?: Partial<
    Record<
      number,
      {
        startedAt: {
          month: number
          year: number
        }
        completedAt: {
          month: number
          year: number
        } | null
      } | null
    >
  >
  lockedDate?: string
  studies?: string[]
}

export type StageStatus = 'unstarted' | 'started' | 'complete'

export type Profile = {
  firstName: string
  lastName: string
  searchFirstName: string
  searchLastName: string
  birthDate: string
  gender: Gender
  diagnosis: Diagnosis
  diagnosisDate: string
  diagnosisVerified: boolean
  diagnosisDocumentation?: string[]
  firstSymptomsDate: string
  height: string
  address: string
  city: string
  state: string
  country: string
  postalCode: string
  phoneNumber: string
  agreedToProfileCheckbox: boolean
}

export type Diagnosis =
  | 'als'
  | 'administrator'
  | 'medical'
  | 'als-probable'
  | 'pls'

export const diagnosisTitles: Record<Diagnosis, string> = {
  als: 'ALS',
  administrator: 'PALS Support Team Member',
  medical: 'Medical Professional or Researcher',
  'als-probable': 'Probable ALS',
  pls: 'PLS',
}
export const shortDiagnosisTitles: Record<Diagnosis, string> = {
  als: 'ALS',
  administrator: 'PSTM',
  medical: 'MP',
  'als-probable': 'Prob-ALS',
  pls: 'PLS',
}

export type Gender = 'male' | 'female' | 'other'

export type UnitSystem = 'metric' | 'imperial'
