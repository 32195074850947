import {
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  useToast,
} from '@chakra-ui/react'
import { map, startCase } from 'lodash'
import stoolType from '../assets/stoolType.jpeg'
import urineType from '../assets/urineType.jpeg'
import MonthlyReportHeader from '../components/MonthlyReportHeader'
import MonthlyReportNavButtons from '../components/MonthlyReportNavButtons'
import useCurrentReport from '../hooks/useCurrentReport'
import useNavigateNext from '../hooks/useNavigateNext'
import useUpdateReportDoc from '../hooks/useUpdateReportDoc'
import {
  giSymptoms,
  odors,
  stoolColors,
  stoolTypes2,
  stoolVolumes,
  urineColors,
} from '../types/StoolQuestionnaire'
import usePurpleBubblesText from '../hooks/usePurpleBubblesText'
import PurpleBubbleContent from '../components/PurpleBubbleContent'

export default () => {
  const state = useCurrentReport()?.stool || { stool: {}, urine: {} }
  const updateReport = useUpdateReportDoc()
  const navNext = useNavigateNext()
  const toast = useToast()
  const purpleBubbleContent = usePurpleBubblesText('Step-4')

  const setState = async (update: typeof state) => {
    const stool = { ...state.stool, ...update.stool }
    const urine = { ...state.urine, ...update.urine }
    stool.complete =
      stool.color !== undefined &&
      stool.daily !== undefined &&
      stool.float !== undefined &&
      stool.odor !== undefined &&
      stool.times !== undefined &&
      stool.type !== undefined &&
      stool.volume !== undefined

    console.log({ stool })

    urine.complete =
      urine.color !== undefined &&
      urine.odor !== undefined &&
      urine.timesDaily !== undefined

    updateReport({
      stool: {
        stool,
        urine,
      },
    })
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()

        if (state.stool.giSymptoms?.length === 0) {
          toast({
            status: 'info',
            title: 'Incomplete',
            description:
              'Please select at least one item in the GI Symptoms list. If you have no symptoms, then select "None"',
          })
        } else navNext()
      }}
    >
      <Stack spacing={6}>
        <MonthlyReportHeader title="Describe your stool and urine." />
        {purpleBubbleContent?.text && (
          <PurpleBubbleContent text={purpleBubbleContent.text} />
        )}
        <Heading size="md">Stool</Heading>
        <Grid gap={6} templateColumns="repeat(5, 1fr)">
          <GridItem colSpan={[5, 2]}>
            <Stack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Stool Type (from Bristol Chart)</FormLabel>
                <Select
                  placeholder="Choose Type"
                  value={state.stool.type}
                  onChange={(e) => {
                    setState({
                      ...state,
                      stool: {
                        ...state.stool,
                        type: parseInt(e.target.value),
                      },
                    })
                  }}
                >
                  {map(stoolTypes2, (value, key) => {
                    return (
                      <option value={key} key={key}>
                        ({key}) {value}
                      </option>
                    )
                  })}
                </Select>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Stool Color</FormLabel>
                <Select
                  placeholder="Choose Color"
                  value={state.stool.color}
                  onChange={(e) => {
                    setState({
                      ...state,
                      stool: {
                        ...state.stool,
                        color: e.target.value,
                      },
                    })
                  }}
                >
                  {stoolColors.map((value) => {
                    return (
                      <option value={value} key={value}>
                        {startCase(value).split(' ').join('-')}
                      </option>
                    )
                  })}
                </Select>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Stool Odor</FormLabel>
                <Select
                  placeholder="Choose Odor"
                  value={state.stool.odor}
                  onChange={(e) => {
                    setState({
                      ...state,
                      stool: {
                        ...state.stool,
                        odor: e.target.value,
                      },
                    })
                  }}
                >
                  {odors.map((value) => {
                    return (
                      <option value={value} key={value}>
                        {startCase(value).split(' ').join('-')}
                      </option>
                    )
                  })}
                </Select>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Average Stool Volume</FormLabel>
                <Select
                  placeholder="Choose Volume"
                  value={state.stool.volume}
                  onChange={(e) => {
                    setState({
                      ...state,
                      stool: {
                        ...state.stool,
                        volume: parseInt(e.target.value),
                      },
                    })
                  }}
                >
                  {map(stoolVolumes, (text, key) => {
                    return (
                      <option value={key} key={key}>
                        {text}
                      </option>
                    )
                  })}
                </Select>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Does your stool float?</FormLabel>
                <RadioGroup
                  onChange={(value) => {
                    setState({
                      ...state,
                      stool: {
                        ...state.stool,
                        float: value === 'yes',
                      },
                    })
                  }}
                  value={
                    state.stool.float === undefined
                      ? undefined
                      : state.stool.float
                      ? 'yes'
                      : 'no'
                  }
                >
                  <Stack>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Do you have stool daily?</FormLabel>
                <RadioGroup
                  onChange={(value) => {
                    setState({
                      ...state,
                      stool: {
                        ...state.stool,
                        daily: value === 'yes',
                      },
                    })
                  }}
                  value={
                    state.stool.daily === undefined
                      ? undefined
                      : state.stool.daily
                      ? 'yes'
                      : 'no'
                  }
                >
                  <Stack>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>
                  How many times per {state.stool.daily ? 'day' : 'week'}?
                </FormLabel>
                <Input
                  type="number"
                  value={state.stool.times}
                  onChange={(e) => {
                    setState({
                      ...state,
                      stool: {
                        times: parseInt(e.target.value),
                      },
                    })
                  }}
                  step="any"
                />
              </FormControl>
            </Stack>
          </GridItem>
          <GridItem colSpan={[5, 3]}>
            <Image src={stoolType} objectFit="contain" />
          </GridItem>
        </Grid>
        <Stack spacing={2}>
          <Heading size="sm">
            GI Symptoms <span style={{ color: 'red' }}>*</span>
          </Heading>
          <SimpleGrid columns={[1, 2, 3]}>
            <Checkbox
              fontWeight="bold"
              onChange={(e) => {
                setState({
                  ...state,
                  stool: {
                    ...state.stool,
                    giSymptoms: e.target.checked ? ['none'] : [],
                  },
                })
              }}
              isChecked={!!state.stool.giSymptoms?.includes('none')}
            >
              None
            </Checkbox>
            {map(giSymptoms, (value, key) => {
              if (key === 'none') return null

              return (
                <Checkbox
                  key={key}
                  onChange={(e) => {
                    let giSymptoms = state.stool.giSymptoms || []
                    if (e.target.checked) {
                      giSymptoms.push(key)
                      giSymptoms = giSymptoms.filter((s) => s !== 'none')
                    } else {
                      giSymptoms = giSymptoms.filter((s) => s !== key)
                    }
                    setState({
                      ...state,
                      stool: {
                        ...state.stool,
                        giSymptoms,
                      },
                    })
                  }}
                  isChecked={!!state.stool.giSymptoms?.includes(key)}
                >
                  {value}
                </Checkbox>
              )
            })}
          </SimpleGrid>
        </Stack>
        <Grid gap={6} templateColumns="repeat(5, 1fr)">
          <GridItem colSpan={[5, 2]}>
            <Stack spacing={4} align="start">
              <Heading size="md">Urine</Heading>
              <FormControl isRequired>
                <FormLabel>Times per day</FormLabel>
                <Input
                  type="number"
                  step="any"
                  value={state.urine.timesDaily}
                  onChange={(e) => {
                    setState({
                      ...state,
                      urine: {
                        ...state.urine,
                        timesDaily: parseInt(e.target.value),
                      },
                    })
                  }}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Urine Color (from chart)</FormLabel>
                <Select
                  placeholder="Choose Color"
                  value={state.urine.color}
                  onChange={(e) => {
                    setState({
                      ...state,
                      urine: {
                        ...state.urine,
                        color: parseInt(e.target.value),
                      },
                    })
                  }}
                >
                  {map(urineColors, (text, key) => {
                    return (
                      <option value={key} key={key}>
                        ({key}) {text}
                      </option>
                    )
                  })}
                </Select>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Urine Odor</FormLabel>
                <Select
                  placeholder="Choose Odor"
                  value={state.urine.odor}
                  onChange={(e) => {
                    setState({
                      ...state,
                      urine: {
                        ...state.urine,
                        odor: e.target.value,
                      },
                    })
                  }}
                >
                  {odors.map((value) => {
                    return (
                      <option value={value} key={value}>
                        {startCase(value).split(' ').join('-')}
                      </option>
                    )
                  })}
                </Select>
              </FormControl>
            </Stack>
          </GridItem>
          <GridItem colSpan={[5, 3]}>
            <Image src={urineType} objectFit="contain" />
          </GridItem>
        </Grid>
        <MonthlyReportNavButtons />
      </Stack>
    </form>
  )
}
