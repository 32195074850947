import {
  Box,
  Button,
  Container,
  Input,
  Link,
  Stack,
  Text
} from '@chakra-ui/react'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import AuthHeader from '../components/AuthHeader'
import useHandleError from '../hooks/useHandleError'
import useSendPasswordResetEmail from '../hooks/useSendPasswordResetEmail'

export default () => {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const handleError = useHandleError()
  const navigate = useNavigate()
  const sendPasswordResetEmail = useSendPasswordResetEmail()

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        setLoading(true)
        signInWithEmailAndPassword(getAuth(), email, password)
          .then(() => {
            navigate('/')
          })
          .catch(e => {
            setLoading(false)
            handleError(e)
          })
      }}
    >
      <Container>
        <Stack spacing={4}>
          <AuthHeader />
          <Stack spacing={2}>
            <Input
              placeholder='Email'
              type='email'
              autoComplete='email'
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <Input
              placeholder='Password'
              type='password'
              autoComplete='current-password'
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </Stack>
          <Button
            type='submit'
            width='full'
            isLoading={loading}
            onClick={() => {
              setLoading(true)
              signInWithEmailAndPassword(getAuth(), email, password)
                .then(() => {
                  navigate('/')
                })
                .catch(e => {
                  setLoading(false)
                  handleError(e)
                })
            }}
          >
            Sign In
          </Button>
          <Stack spacing={1}>
            <Text fontSize='medium'>
              Don't have an account?{' '}
              <Link as={RouterLink} to='/sign-up' color='purple.600'>
                Sign Up
              </Link>
            </Text>
            <Box>
              <Button
                size='sm'
                variant='link'
                onClick={() => sendPasswordResetEmail(email)}
              >
                Forgot Your Password?
              </Button>
            </Box>
          </Stack>
        </Stack>
      </Container>
    </form>
  )
}
