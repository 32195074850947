import { useLocation } from 'react-router-dom'

export default () => {
  const [year, month] = useReportingForParam().split('-')

  return { year: parseInt(year), month: parseInt(month) }
}

export const useReportingForParam = () => {
  const location = useLocation()
  const parts = location.pathname.split('/')
  return parts[2]
}
