import { RouteObject } from 'react-router-dom'

import Landing from '../pages/Landing'
import SignIn from '../pages/SignIn'
import SignUp from '../pages/SignUp'

const guestRoutes: RouteObject[] = [
  { path: '/sign-up', element: <SignUp /> },
  { path: '/sign-in', element: <SignIn /> },
  { path: '/', element: <Landing /> },
]

export default guestRoutes
