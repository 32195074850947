export type Lab = {
  title: string;
  panels: Panel[];
  archived: boolean;
};

export type Range = {
  bottom: number;
  top: number;
};

export type PanelItem = {
  id: string;
  title: string;
  unit: BloodWorkUnit;
  referenceRange: Range;
  optimalRange?: Range;
  archived?: boolean;
};

export type Panel = {
  id: string;
  title: string;
  items: PanelItem[];
  archived?: boolean;
};

export const cmp: Panel = {
  id: "cmp",
  title: "Comprehensive Metabolic Panel",
  items: [
    {
      id: "sodium",
      title: "Sodium Level",
      unit: "mmoll",
      referenceRange: {
        bottom: 136,
        top: 146,
      },
    },
    {
      id: "potassium",
      title: "Potassium Level",
      unit: "mmoll",
      referenceRange: {
        bottom: 3.5,
        top: 5,
      },
      optimalRange: {
        bottom: 7,
        top: 8,
      },
    },
    {
      id: "Chloride",
      title: "Chloride Level",
      unit: "mmoll",
      referenceRange: {
        bottom: 98,
        top: 108,
      },
    },
    {
      id: "co2",
      title: "CO2 Level",
      unit: "mmoll",
      referenceRange: {
        bottom: 22,
        top: 34,
      },
    },
    {
      id: "ureaNitrogen",
      title: "Urea Nitrogen",
      unit: "mgdl",
      referenceRange: {
        bottom: 8,
        top: 20,
      },
    },
    {
      id: "creatinine",
      title: "Creatinine",
      unit: "mgdl",
      referenceRange: {
        bottom: 0.7,
        top: 1.3,
      },
    },
    {
      id: "glucose",
      title: "Glucose Level",
      unit: "mgdl",
      referenceRange: {
        bottom: 73,
        top: 100,
      },
    },
  ],
};

export const bloodWorkUnits = {
  "10e3ul": "10E3/uL",
  cellsmm3: "cells/mm3",
  cellsμl: "cells/μL",
  fl: "fL",
  gdl: "g/dL",
  gigal: "Giga/L",
  gl: "g/L",
  iuml: "IU/mL",
  kcumm: "K/Cumm",
  keynumber: "Key #",
  kul: "k/uL",
  mcgdl: "mcg/dL",
  mcgg_creatinine: "mcg/g creatinine",
  mcgml: "mcg/mL",
  mcmoll: "mcmol/L",
  mgday_rec: "mg/day rec",
  mgdl: "mg/dL",
  mgl: "mg/L",
  mlmin173: "mL/min/1.73",
  mmhr: "mm/hr",
  mmolg_creatinine: "mmol/g creatinine",
  mmoll: "mmol/L",
  mmolmol_creatinine: "mmol/mol creatinine",
  ngdl: "ng/dL",
  ngg_creatinine: "ng/g creatinine",
  ngml: "ng/mL",
  nmoll: "nmol/L",
  nutrient_need_number: "Nutrient Need #",
  percent: "Percent",
  pg: "pg",
  pgml: "pg/mL",
  ppb: "ppb",
  ugdl: "ug/dL",
  uil: "IU/L",
  ul: "U/L",
  umolm: "umol/m",
  μUmL: "μU/mL",
  μgdl: "μg/dL",
};

export type BloodWorkUnit = keyof typeof bloodWorkUnits;
