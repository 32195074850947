import Questionnaire from '../types/Questionnaire'

export const alsfrs: Questionnaire = {
  key: 'alsfrs',
  title: 'ALSFRS-R',
  subtitle: 'Update your ALS Functional Rating Score',
  questions: {
    speech: {
      title: 'How would you describe your speech?',
      options: [
        [
          {
            text: 'Normal',
            points: 4
          },
          {
            text: 'Detectable speech disturbance',
            points: 3
          },
          {
            text: 'Intelligible with repeating',
            points: 2
          },
          {
            text: 'Speech combined with nonvocal communications',
            points: 1
          },
          {
            text: 'Loss of useful speech',
            points: 0
          }
        ]
      ]
    },
    salivation: {
      title: 'How would you describe your salivation?',
      options: [
        [
          {
            text: 'Normal',
            points: 4
          },
          {
            text: 'Slight but definite excess of saliva in mouth; may have nighttime drooling',
            points: 3
          },
          {
            text: 'Moderately excessive saliva; may have minimal drooling',
            points: 2
          },
          {
            text: 'Marked excess of saliva with some drooling',
            points: 1
          },
          {
            text: 'Marked drooling; requires constant tissue or handkerchief',
            points: 0
          }
        ]
      ]
    },
    swallowing: {
      title: 'How would you describe your swallowing?',
      options: [
        [
          {
            text: 'Normal eating habits',
            points: 4
          },
          {
            text: 'Early eating problems; occasional choking',
            points: 3
          },
          {
            text: 'Dietary consistency changes',
            points: 2
          },
          {
            text: 'Needs supplemental tube feedings',
            points: 1
          },
          {
            text: 'Nothing by mouth; exclusively parenteral or enteral feeding',
            points: 1
          }
        ]
      ]
    },
    handwriting: {
      title: 'How would you describe your handwriting?',
      options: [
        [
          {
            points: 4,
            text: 'Normal'
          },
          {
            points: 3,
            text: 'Slow or sloppy; all words are legible'
          },
          {
            points: 2,
            text: 'Not all words are legible'
          },
          {
            points: 1,
            text: 'Able to grip pen but unable to write'
          },
          {
            points: 0,
            text: 'Unable to grip pen'
          }
        ]
      ]
    },
    feeding: {
      title: 'Feeding',
      switch: 'Do you use a feeding tube? (>50% of the time)',
      options: [
        [
          {
            points: 4,
            text: 'Normal'
          },
          {
            points: 3,
            text: 'Clumsy but able to perform all manipulations independently'
          },
          {
            points: 2,
            text: 'Some help needed with closures and fasteners'
          },
          {
            points: 1,
            text: 'Provides minimal assistance to caregiver'
          },
          {
            points: 0,
            text: 'Unable to perform any aspect of task'
          }
        ],
        [
          {
            points: 4,
            text: 'Normal'
          },
          {
            points: 3,
            text: 'Somewhat slow and clumsy but no help needed'
          },
          {
            points: 2,
            text: 'Can cut most foods although clumsy and slow; some help needed'
          },
          {
            points: 1,
            text: 'Food must be cut by someone but can still feed slowly'
          },
          {
            points: 0,
            text: 'Needs to be fed'
          }
        ]
      ]
    },
    dressingHygiene: {
      title: 'How would you describe your dressing and hygiene?',
      options: [
        [
          {
            points: 4,
            text: 'Normal function'
          },
          {
            points: 3,
            text: 'Independent and complete self-care with effort or decreased efficiency'
          },
          {
            points: 2,
            text: 'Intermittent assistance or substitute methods'
          },
          {
            points: 1,
            text: 'Needs attendant for self-care'
          },
          {
            points: 0,
            text: 'Total dependence'
          }
        ]
      ]
    },
    turningInBed: {
      title:
        'How would you describe your turning in bed and adjusting bed clothes',
      options: [
        [
          {
            points: 4,
            text: 'Normal'
          },
          {
            points: 3,
            text: 'Somewhat slow and clumsy but no help needed'
          },
          {
            points: 2,
            text: 'Can turn alone or adjust sheets but with great difficulty'
          },
          {
            points: 1,
            text: 'Can initiate but not turn or adjust sheets alone'
          },
          {
            points: 0,
            text: 'Helpless'
          }
        ]
      ]
    },
    walking: {
      title: 'How would you describe your walking?',
      options: [
        [
          {
            points: 4,
            text: 'Normal'
          },
          {
            points: 3,
            text: 'Early ambulation difficulties'
          },
          {
            points: 2,
            text: 'Walks with assistance'
          },
          {
            points: 1,
            text: 'Nonambulatory functional movement'
          },
          {
            points: 0,
            text: 'No purposeful leg movement'
          }
        ]
      ]
    },
    stairs: {
      title: 'How would you describe your climbing stairs?',
      options: [
        [
          {
            points: 4,
            text: 'Normal'
          },
          {
            points: 3,
            text: 'Slow'
          },
          {
            points: 2,
            text: 'Mild unsteadiness or fatigue'
          },
          {
            points: 1,
            text: 'Needs assistance'
          },
          {
            points: 0,
            text: 'Cannot do'
          }
        ]
      ]
    },
    dyspnea: {
      title: 'How would you describe your dyspnea (difficulty breathing)?',
      options: [
        [
          {
            points: 4,
            text: 'None'
          },
          {
            points: 3,
            text: 'Occurs when walking'
          },
          {
            points: 2,
            text: 'Occurs with one or more of the following: eating, bathing, dressing'
          },
          {
            points: 1,
            text: 'Occurs at rest, difficulty breathing when either sitting or lying'
          },
          {
            points: 0,
            text: 'Significant difficulty, considering using mechanical respiratory support'
          }
        ]
      ]
    },
    orthopnea: {
      title:
        'How would you describe your orthopnea (breathlessness while lying down)?',
      options: [
        [
          {
            points: 4,
            text: 'None'
          },
          {
            points: 3,
            text: 'Some difficulty sleeping at night due to shortness of breath; does not routinely use >2 pillows'
          },
          {
            points: 2,
            text: 'Needs extra pillows in order to sleep (>2)'
          },
          {
            points: 1,
            text: 'Can only sleep sitting up'
          },
          {
            points: 0,
            text: 'Unable to sleep'
          }
        ]
      ]
    },
    respiratory: {
      title: 'How would you describe your respiratory insufficiency?',
      options: [
        [
          {
            points: 4,
            text: 'None'
          },
          {
            points: 3,
            text: 'Intermittent use of Mechanical Ventilation (such as BiPAP)'
          },
          {
            points: 2,
            text: 'Continuous use of Mechanical Ventilation (such as BiPAP) during the night'
          },
          {
            points: 1,
            text: 'Continuous use of Mechanical Ventilation (such as BiPAP) during the night and day'
          },
          {
            points: 0,
            text: 'Invasive mechanical ventilation by intubation or tracheostomy'
          }
        ]
      ]
    }
  }
}
