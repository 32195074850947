import { Stack } from '@chakra-ui/react'
import MonthlyReportHeader from '../components/MonthlyReportHeader'
import useNavigateNext from '../hooks/useNavigateNext'
import { useCurrentOrPreviousReportsArray } from '../hooks/useReports'
import useUpdateReportDoc from '../hooks/useUpdateReportDoc'
import FamilySupport from './FamilySupport'
import MedicalSupport from './MedicalSupport'
import usePurpleBubblesText from '../hooks/usePurpleBubblesText'
import PurpleBubbleContent from '../components/PurpleBubbleContent'

export default () => {
  const navNext = useNavigateNext()
  const updateReport = useUpdateReportDoc()
  const currentOrPastReports = useCurrentOrPreviousReportsArray()
  const purpleBubbleContent = usePurpleBubblesText('Step-13')

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        navNext()

        const emotionalSupportPeople =
          currentOrPastReports[0].emotionalSupportPeople ||
          currentOrPastReports[1].emotionalSupportPeople ||
          []

        const medicalSupport =
          currentOrPastReports[0].medicalSupport ||
          currentOrPastReports[1].medicalSupport ||
          {}
        updateReport({
          support: true,
          emotionalSupportPeople,
          medicalSupport,
        })
      }}
    >
      <Stack spacing={5}>
        <MonthlyReportHeader title="Tell us about your Support Team." />
        {purpleBubbleContent?.text && (
          <PurpleBubbleContent text={purpleBubbleContent.text} />
        )}
        <FamilySupport />
        <MedicalSupport />
      </Stack>
    </form>
  )
}
