export const emotionalSupportLevels = [
  {
    value: 5,
    title: 'Extremely Supportive'
  },
  {
    value: 4,
    title: 'Very Supportive'
  },
  {
    value: 3,
    title: 'Average Support'
  },
  {
    value: 2,
    title: 'Slightly Negative'
  },
  {
    value: 1,
    title: 'Very Negative or Oppositional'
  },
  { value: 0, title: 'Not Applicable' }
]

export const emotionalSupportTypes = {
  spouse: 'Spouse/Significant Other',
  family: 'Family Member',
  friend: 'Friend',
  caregiver: 'Caregiver',
  colleague: 'Colleague',
  supportGroup: 'Support Group',
  functionalPractitioner: 'Functional Medical Practitioner',
  conventionalPractitioner: 'Conventional Practitioner'
}

export const supportVenues = {
  inPerson: 'In Person',
  virtual: 'Virtual',
  both: 'Both'
}

export type SupportVenue = keyof typeof supportVenues

export type EmotionalSupportType = keyof typeof emotionalSupportTypes

export type EmotionalSupportPerson = {
  id: string
  type: EmotionalSupportType
  emotionalSupportLevel: number
  physicalSupportLevel: number
  nickname: string
  venue: SupportVenue
}

export type MedicalSupportMonth = {
  [key: string]: {
    virtual: number
    inPerson: number
    communications: number
  } | null
}
