import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Link,
  Select,
  SimpleGrid,
  Stack,
  Text,
  useToast
} from '@chakra-ui/react'
import { getAuth, signOut } from 'firebase/auth'
import {
  collection,
  doc,
  getFirestore,
  updateDoc
} from 'firebase/firestore'
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes
} from 'firebase/storage'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CountrySelect from '../components/CountrySelect'
import useHandleError from '../hooks/useHandleError'
import useSendPasswordResetEmail from '../hooks/useSendPasswordResetEmail'
import useStore from '../store/useStore'
import FirestoreUser, {
  Diagnosis,
  Gender,
  Profile,
  UnitSystem,
  diagnosisTitles
} from '../types/FirestoreUser'
import isRawDateValid from '../utils/isRawDateValid'
import userDoc from '../utils/userDoc'

export default ({
  uid,
  firestoreUser
}: {
  uid: string
  firestoreUser: FirestoreUser
}) => {
  const myUid = useStore(s => s.user?.uid)
  const isMine = useMemo(() => {
    return uid === myUid
  }, [uid, myUid])
  const { _profile, units } = useMemo(() => {
    return {
      _profile: firestoreUser?.profile,
      units: firestoreUser?.unitSystem || 'imperial'
    }
  }, [firestoreUser])
  const [loading, setLoading] = useState(false)
  const [profile, setProfile] = useState<Partial<Profile>>(_profile || {})
  const [files, setFiles] = useState<FileList | null>(null)
  const handleError = useHandleError()
  const toast = useToast()
  const navigate = useNavigate()

  const extraInfoRequired =
    !!profile.diagnosis &&
    !['medical', 'administrator'].includes(profile.diagnosis)

  const update = useCallback(
    (partial: Partial<Profile>) => {
      setProfile({
        ...profile,
        ...partial
      })
    },
    [setProfile, profile]
  )

  useEffect(() => {
    if (_profile) {
      update(_profile)
    }
    // eslint-disable-next-line
  }, [_profile])

  const submit = async () => {
    if (isMine) {
      const user = getAuth().currentUser
      if (!user) return alert('Not logged in!')

      if (!profile.agreedToProfileCheckbox) {
        return toast({
          status: 'warning',
          title: 'Warning',
          description:
            'Please check the check box stating that you agree to the terms and conditions.',
          isClosable: true
        })
      }
    }

    if (profile.birthDate && !isRawDateValid(profile.birthDate)) {
      return toast({
        status: 'error',
        title: 'Error',
        description:
          'Birth Date is in an incorrect format. Please enter in YYYY-MM-DD format.'
      })
    }

    if (profile.diagnosisDate && !isRawDateValid(profile.diagnosisDate)) {
      return toast({
        status: 'error',
        title: 'Error',
        description:
          'Diagnosis Date is in an incorrect format. Please enter in YYYY-MM-DD format.'
      })
    }

    if (
      profile.firstSymptomsDate &&
      !isRawDateValid(profile.firstSymptomsDate)
    ) {
      return toast({
        status: 'error',
        title: 'Error',
        description:
          'First Symptoms Date is in an incorrect format. Please enter in YYYY-MM-DD format.'
      })
    }

    setLoading(true)
    try {
      if (files && files.length > 0) {
        const urls = profile.diagnosisDocumentation || []
        for (let i = 0; i < files.length; i++) {
          const file = files.item(i)
          if (!file) continue
          const { id } = doc(collection(getFirestore(), 'a'))
          console.log('upload to', id)

          const storageRef = ref(
            getStorage(),
            `users/${myUid}/documents/${id}.pdf`
          )
          await uploadBytes(storageRef, file)
          const url = await getDownloadURL(storageRef)
          urls.push(url)
        }
        profile.diagnosisDocumentation = urls
      }

      if (!profile.diagnosisVerified) {
        profile.diagnosisVerified = false
      }
      profile.firstName = profile.firstName?.trim()
      profile.lastName = profile.lastName?.trim()

      profile.searchFirstName = profile.firstName?.toLowerCase()
      profile.searchLastName = profile.lastName?.toLowerCase()
      if (!profile.diagnosisDate) delete profile.diagnosisDate
      if (!profile.firstSymptomsDate) delete profile.firstSymptomsDate

      await updateDoc(userDoc(uid), {
        profile
      })

      toast({
        status: 'success',
        title: 'Saved',
        description: 'Profile was successfully saved!',
        isClosable: true
      })
    } catch (e) {
      handleError(e)
    } finally {
      setLoading(false)
    }
  }

  const docsLength = profile.diagnosisDocumentation?.length || 0

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        submit()
      }}
    >
      <Stack spacing={5}>
        <SimpleGrid
          spacing={3}
          columns={{
            sm: 1,
            md: 2
          }}
        >
          <FormControl>
            <FormLabel>First Name</FormLabel>
            <Input
              required
              autoComplete='given-name'
              defaultValue={profile.firstName || ''}
              onChange={e => {
                update({ firstName: e.target.value })
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Last Name</FormLabel>
            <Input
              required
              autoComplete='family-name'
              defaultValue={profile.lastName || ''}
              onChange={e => {
                update({ lastName: e.target.value })
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Unit System</FormLabel>
            <Select
              placeholder='Choose Unit System'
              defaultValue={units}
              onChange={e => {
                const user = getAuth().currentUser
                if (!user) return

                updateDoc(userDoc(user.uid), {
                  unitSystem: e.target.value as UnitSystem
                })
              }}
            >
              <option value='imperial'>USA / Imperial</option>
              <option value='metric'>Metric</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Height</FormLabel>
            <InputGroup>
              <Input
                required={extraInfoRequired}
                defaultValue={profile.height || ''}
                onChange={e => {
                  update({ height: e.target.value })
                }}
              />
              <InputRightAddon>
                {units === 'metric' ? 'cm' : 'in'}
              </InputRightAddon>
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel>Gender</FormLabel>
            <Select
              placeholder='Gender'
              defaultValue={profile.gender || ''}
              required
              onChange={e => {
                update({ gender: e.target.value as Gender })
              }}
            >
              <option value='male'>Male</option>
              <option value='female'>Female</option>
              <option value='other'>Other</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Date of Birth (YYYY-MM-DD)</FormLabel>
            <Input
              required={extraInfoRequired}
              defaultValue={profile.birthDate || ''}
              onChange={e => {
                update({ birthDate: e.target.value })
              }}
              placeholder='YYYY-MM-DD'
            />
          </FormControl>
          <FormControl>
            <FormLabel>Diagnosis or User Type</FormLabel>
            <Select
              placeholder='--'
              required
              defaultValue={profile.diagnosis || ''}
              onChange={e => {
                const diagnosis = e.target.value as Diagnosis
                if (['office', 'medical'].includes(diagnosis)) {
                  update({
                    diagnosis,
                    diagnosisDate: '',
                    firstSymptomsDate: ''
                  })
                } else {
                  update({
                    diagnosis
                  })
                }
              }}
            >
              {diagnoses.map(diagnosis => {
                return (
                  <option value={diagnosis} key={diagnosis}>
                    {diagnosisTitles[diagnosis]}
                  </option>
                )
              })}
            </Select>
          </FormControl>
          <div></div>
          <FormControl>
            <FormLabel>Date of First Symptoms (YYYY-MM-DD)</FormLabel>
            <Input
              defaultValue={profile.firstSymptomsDate || ''}
              required={extraInfoRequired}
              isDisabled={!extraInfoRequired}
              onChange={e => {
                update({ firstSymptomsDate: e.target.value })
              }}
              placeholder='YYYY-MM-DD'
            />
          </FormControl>
          <FormControl>
            <FormLabel>Date of Diagnosis (YYYY-MM-DD)</FormLabel>
            <Input
              required={extraInfoRequired}
              isDisabled={!extraInfoRequired}
              placeholder='YYYY-MM-DD'
              defaultValue={profile.diagnosisDate || ''}
              onChange={e => {
                update({ diagnosisDate: e.target.value })
              }}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Street Address</FormLabel>
            <Input
              required
              autoComplete='address'
              defaultValue={profile.address || ''}
              onChange={e => {
                update({ address: e.target.value })
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>City</FormLabel>
            <Input
              required
              autoComplete='address-level2'
              defaultValue={profile.city || ''}
              onChange={e => {
                update({ city: e.target.value })
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>State/Province/Other</FormLabel>
            <Input
              required
              autoComplete='address-level1'
              defaultValue={profile.state || ''}
              onChange={e => {
                update({ state: e.target.value })
              }}
            />
          </FormControl>
          <CountrySelect
            defaultValue={profile.country}
            onChange={country => update({ country })}
          />
          <FormControl>
            <FormLabel>Postal Code</FormLabel>
            <Input
              required
              autoComplete='postal-code'
              defaultValue={profile.postalCode || ''}
              onChange={e => {
                update({ postalCode: e.target.value })
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Phone Number</FormLabel>
            <Input
              required
              autoComplete='tel'
              defaultValue={profile.phoneNumber || ''}
              onChange={e => {
                update({ phoneNumber: e.target.value })
              }}
            />
          </FormControl>
        </SimpleGrid>
        {profile.diagnosisVerified ? (
          <Alert status='success'>
            <AlertIcon />
            Your diagnosis was verified by our admin team.
          </Alert>
        ) : (
          docsLength > 0 &&
          !profile.diagnosisVerified && (
            <Alert status='info'>
              <AlertIcon />
              You've uploaded {docsLength} verification
              {docsLength === 1 ? ' document' : ' documents'}. We will let
              you after we complete our review.
            </Alert>
          )
        )}
        <FormControl>
          <FormLabel>Diagnosis Documentation</FormLabel>
          <Input
            variant='unstyled'
            type='file'
            onChange={e => setFiles(e.target.files)}
            accept='.pdf,.jpg,.jpeg,.png'
            multiple
          />
        </FormControl>
        {!!profile.diagnosisDocumentation?.length && (
          <Stack>
            <Text fontWeight='bold'>Uploaded Documents: </Text>
            <Stack spacing={0}>
              {profile.diagnosisDocumentation.map((url, index) => {
                return (
                  <Link color='purple.600' key={url} href={url} isExternal>
                    Document #{index + 1}
                  </Link>
                )
              })}
            </Stack>
          </Stack>
        )}
        {isMine && (
          <>
            <Text>
              Under penalty of perjury, I acknowledge that I have read and
              agree to the{' '}
              <Link href='/terms' isExternal color='purple.600'>
                Terms and Conditions
              </Link>{' '}
              . I acknowledge that all information contained in the
              Registry or about any member is confidential. I acknowledge
              that I am not employed by nor am a contractor for a
              governmental nor pharmaceutical organization, either directly
              or indirectly, including but not limited to the FDA, FTC, CDC
              or NIH. This registry is for educational purposes only, and
              is not intended to replace medical prescriptions nor advice
              from your doctors.
            </Text>
            <Checkbox
              isChecked={profile.agreedToProfileCheckbox}
              onChange={e =>
                update({
                  agreedToProfileCheckbox: e.target.checked
                })
              }
            >
              I Agree to the Statement Above
            </Checkbox>
          </>
        )}
        <Box>
          <Button
            type='submit'
            disabled={loading}
            isLoading={loading}
            size='lg'
          >
            Save
          </Button>
        </Box>
        {isMine && (
          <>
            <ChangePassword />
            <Box>
              <Button
                colorScheme='red'
                variant='link'
                onClick={() => {
                  if (window.confirm('Sign out?')) {
                    signOut(getAuth()).then(() => navigate('/'))
                  }
                }}
              >
                Sign Out
              </Button>
            </Box>
          </>
        )}
      </Stack>
    </form>
  )
}

const ChangePassword = () => {
  const sendPasswordResetEmail = useSendPasswordResetEmail()

  return (
    <Box>
      <Button
        variant='link'
        onClick={() => {
          const email = getAuth().currentUser?.email
          if (!email) return
          sendPasswordResetEmail(email)
        }}
      >
        Change Password
      </Button>
    </Box>
  )
}

const diagnoses: Diagnosis[] = [
  'als',
  'pls',
  'als-probable',
  'administrator',
  'medical'
]
