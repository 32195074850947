import { useToast } from '@chakra-ui/react'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import useHandleError from './useHandleError'

export default () => {
  const toast = useToast()
  const handleError = useHandleError()

  return (email: string) => {
    sendPasswordResetEmail(getAuth(), email)
      .then(() => {
        toast({
          status: 'success',
          title: 'Password Reset Email Sent',
          description: `Sent to email address: ${email}. Please also check your spam folder.`,
          duration: 10000,
          isClosable: true
        })
      })
      .catch(handleError)
  }
}
