import { Button, Heading, Stack, Text, VStack } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export default ({
  title,
  subtitle
}: {
  title?: string
  subtitle: string
}) => {
  return (
    <VStack alignItems='center' spacing={5}>
      <Stack spacing={0}>
        <Heading textAlign='center'>{title || 'Error'}</Heading>
        <Text fontStyle='italic' textAlign='center'>
          {subtitle}
        </Text>
      </Stack>
      <Link to='/'>
        <Button>Return Home</Button>
      </Link>
    </VStack>
  )
}
