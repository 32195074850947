import { useEffect } from 'react'
import { collection, getFirestore, onSnapshot } from 'firebase/firestore'
import { map } from 'lodash'
import useStore, { LocalConfig } from '../store/useStore'
import ConfigList from '../types/ConfigList'

const useFetchConfig = () => {
  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(getFirestore(), 'config'),
      (snap) => {
        const config: LocalConfig = {}

        snap.forEach((doc) => {
          if (doc.id === 'stages') {
            useStore.setState({ stages: doc.data().value })
          } else if (doc.id === 'meta') {
            useStore.setState({ meta: doc.data() as any })
          } else {
            const data = doc.data() as ConfigList
            config[doc.id] = {}

            // add items only if they are not archived
            map(data.value, (value, key) => {
              if (!data.archivedIds?.includes(key)) {
                config[doc.id][key] = value
              }
            })
          }
        })

        useStore.setState({ config })
      }
    )

    return () => unsubscribe()
  }, [])
}

export default useFetchConfig
