import { chain } from 'lodash'

export default interface Questionnaire {
  key: 'alssq' | 'alsfrs'
  title: string
  subtitle: string
  questions: Record<string, Question>
}

export interface Question {
  title: string
  switch?: string
  options: Option[][]
}

export interface Option {
  text: string
  points: number
}

export type Answer = {
  set?: number
  option?: number
}

export function maxQuestionnaireScore(questionnaire: Questionnaire) {
  return chain(questionnaire.questions)
    .map(q => q.options[0][0].points)
    .sum()
    .value()
}
