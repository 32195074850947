import { AddIcon, QuestionIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { useState } from 'react'
import { useLabs } from '../data/labsCollection'
import { Range, bloodWorkUnits } from '../types/bloodWork'

export default () => {
  const [labId, setLabId] = useState<string>()
  const [myPanels, setMyPanels] = useState<string[]>([])
  const labs = useLabs({ allowArchived: false })

  const lab = !labId
    ? undefined
    : labs.find(doc => doc.id === labId)?.data()

  return (
    <Stack spacing={5}>
      <Heading>Add New Lab Test</Heading>
      <FormControl maxW='md' isRequired>
        <FormLabel>Lab</FormLabel>
        <Select
          placeholder='Choose Lab'
          value={labId}
          onChange={e => {
            setLabId(e.target.value)
          }}
        >
          {labs.map(doc => {
            return (
              <option value={doc.id} key={doc.id}>
                {doc.data().title}
              </option>
            )
          })}
        </Select>
      </FormControl>
      <Box>
        <Menu>
          <MenuButton
            isDisabled={!labId}
            as={Button}
            rightIcon={<AddIcon />}
          >
            Add Panel
          </MenuButton>
          <MenuList>
            <MenuGroup title={lab ? `${lab.title} Panels` : '?'}>
              {lab?.panels.map(({ id, title }) => {
                return (
                  <MenuItem
                    key={id}
                    isDisabled={!!myPanels.find(mp => mp === id)}
                    onClick={() => {
                      console.log('adding panel', id)
                      setMyPanels([...myPanels, id])
                    }}
                  >
                    {title}
                  </MenuItem>
                )
              })}
            </MenuGroup>
          </MenuList>
        </Menu>
      </Box>
      <Divider />
      <Stack spacing={5}>
        {myPanels.map(myPanel => {
          const panel = lab?.panels.find(panel => panel.id === myPanel)
          if (!panel) return null
          return (
            <Stack key={panel.id}>
              <Stack spacing={1}>
                <Heading size='md'>{panel.title}</Heading>
                <Box>
                  <Button
                    size='xs'
                    variant='link'
                    colorScheme='red'
                    onClick={() => {
                      if (window.confirm('Remove ' + panel.title + '?')) {
                        setMyPanels(myPanels =>
                          myPanels.filter(mp => mp !== myPanel)
                        )
                      }
                    }}
                  >
                    Remove
                  </Button>
                </Box>
              </Stack>
              <TableContainer>
                <Table size='sm' variant='unstyled'>
                  <Thead>
                    <Tr>
                      <Th>Test</Th>
                      <Th>Result</Th>
                      <Th>Reference Range</Th>
                      <Th>
                        Optimal Range{' '}
                        <Popover>
                          <PopoverTrigger>
                            <QuestionIcon color='gray.400' />
                          </PopoverTrigger>
                          <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverHeader
                              fontWeight='normal'
                              textTransform='none'
                              whiteSpace='normal'
                              fontSize='md'
                            >
                              Optimal Range
                            </PopoverHeader>
                            <PopoverBody
                              fontWeight='normal'
                              textTransform='none'
                              whiteSpace='normal'
                              fontSize='md'
                            >
                              Optimal range will only appear if it is
                              different from the reference range.
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {panel.items.map(
                      (
                        { referenceRange, title, optimalRange, unit },
                        id
                      ) => {
                        const formatRange = (range: Range) => {
                          return `${range.bottom} - ${range.top} ${bloodWorkUnits[unit]}`
                        }
                        return (
                          <Tr key={id}>
                            <Td>{title}</Td>
                            <Td>
                              <Input
                                size='sm'
                                placeholder='Result'
                                maxW='80px'
                              />
                            </Td>
                            <Td>{formatRange(referenceRange)}</Td>
                            <Td>
                              {optimalRange
                                ? formatRange(optimalRange)
                                : ''}
                            </Td>
                          </Tr>
                        )
                      }
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </Stack>
          )
        })}
      </Stack>
    </Stack>
  )
}
