import { chain } from 'lodash'
import { useMemo } from 'react'
import useStore from '../store/useStore'
import { useReportingForParam } from './useReportingForMonth'

const useReports = () => useStore(state => state.reports)
export default useReports

export const useReportsArray = () => {
  const reports = useReports()

  return chain(reports)
    .map((report, id) => ({ id, ...report }))
    .orderBy('id', 'desc')
    .value()
}

export const useCurrentOrPreviousReportsArray = () => {
  const reports = useReportsArray()
  const reportingFor = useReportingForParam()

  const currentOrPastReports = useMemo(() => {
    return reports.filter(r => r.id <= reportingFor)
  }, [reports, reportingFor])

  return currentOrPastReports
}
