import { Navigate, Route, Routes, RouteObject } from 'react-router-dom'
import { User } from 'firebase/auth'

import Home from '../pages/Home'
import NotFound from '../pages/NotFound'

import adminRoutes from './AdminRoutes'
import advancedRoutes from './AdvancedRoutes'
import guestRoutes from './GuestRoutes'
import userRoutes from './UserRoutes'

type AppRoutesProps = {
  user: User | null
  isAdmin: boolean
  hasAdvancedPermissions: boolean
}

const renderRoutes = (routes: RouteObject[]) =>
  routes.map((route) => {
    const { path, element, children } = route
    return (
      <Route key={path} path={path} element={element}>
        {children && renderRoutes(children)}
      </Route>
    )
  })

const AppRoutes = ({
  user,
  isAdmin,
  hasAdvancedPermissions,
}: AppRoutesProps) => {
  return (
    <Routes>
      <Route path="/app" element={<Navigate to="/" replace />} />
      {!user && renderRoutes(guestRoutes)}
      {user && (
        <>
          {isAdmin && renderRoutes(adminRoutes)}
          {renderRoutes(userRoutes)}
          {hasAdvancedPermissions && renderRoutes(advancedRoutes)}
          <Route path="/" element={<Home />} />
        </>
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default AppRoutes
