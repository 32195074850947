import { doc, getFirestore } from 'firebase/firestore'
import useStore from '../store/useStore'
import useReportDocId from './useReportDocId'

export default () => {
  const month = useReportDocId()
  const user = useStore(state => state.user)

  if (!user)
    throw new Error(
      'Looking for reporting month but no user is available.'
    )

  return doc(getFirestore(), `users/${user.uid}/reports/${month}`)
}
