import {
  Stack,
  Heading,
  TableContainer,
  Table,
  Text,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  List,
} from '@chakra-ui/react'
import { useMemo, useCallback } from 'react'
import { Reports } from '../../store/useStore'
import { formatMonth } from '../../utils/formatMonth'
import { PANASSectionReport } from '../PANAS'
import { map } from 'lodash'

/** Type Definitions */
type PanasType = 'main' | 'supplemental' | 'modifiedPanas'

interface PANASData {
  key: string
  section: PANASSectionReport
}

/** Untility Functions */
const getFilteredData = (data: PANASData[], panasType: PanasType) => {
  return data.filter((item) => {
    // Filter out all main and supplemental results after April 2024
    if (panasType === 'main' || panasType === 'supplemental') {
      const [month, year] = formatMonth(item.key).split('-')
      const date = new Date(`${month}-01-${year}`)
      const cutoffDate = new Date('apr-01-2024')
      return date <= cutoffDate
    }
    // Filter out any modified panas prior to May 2024
    if (panasType === 'modifiedPanas') {
      const [month, year] = formatMonth(item.key).split('-')
      const date = new Date(`${month}-01-${year}`)
      const cutoffDate = new Date('may-01-2024')
      return date >= cutoffDate
    }
    return true
  })
}

const getHeadingText = (panasType: PanasType) => {
  switch (panasType) {
    case 'main':
      return 'PANAS'
    case 'supplemental':
      return 'PANAS Supplemental'
    case 'modifiedPanas':
      return 'PANAS Modified'
    default:
      return 'PANAS'
  }
}

/** Child Components */
const NoRecordsView = ({ headingText }: { headingText: string }) => (
  <Stack>
    <Heading size="md">{headingText}</Heading>
    <Text>No records.</Text>
  </Stack>
)

const TableRow = ({ item }: { item: PANASData }) => (
  <Tr key={item.key}>
    <Td>{formatMonth(item.key)}</Td>
    <Td isNumeric>
      {item.section.complete ? item.section.positive : 'Incomplete'}
    </Td>
    <Td isNumeric>
      {item.section.complete ? item.section.negative : 'Incomplete'}
    </Td>
  </Tr>
)

const TableView = ({
  headingText,
  renderTableRows,
}: {
  headingText: string
  renderTableRows: () => JSX.Element[]
}) => (
  <Stack>
    <Heading size="md">{headingText}</Heading>
    <TableContainer>
      <Table size="sm" variant="unstyled">
        <Thead>
          <Tr>
            <Th>Month</Th>
            <Th isNumeric>Positive Affect Score</Th>
            <Th isNumeric>Negative Affect Score</Th>
          </Tr>
        </Thead>
        <Tbody>{renderTableRows()}</Tbody>
      </Table>
    </TableContainer>
    <List></List>
  </Stack>
)

/** Main Component */
const PANAS = ({
  panasType,
  reports,
}: {
  panasType: PanasType
  reports: Reports
}) => {
  /** Component Hooks */
  // Memorise PANAS reports data extraction
  const data = useMemo(() => {
    const data: PANASData[] = []
    map(reports, (value, key) => {
      const section = value?.panas?.[panasType]
      if (section) {
        data.push({ key, section })
      }
    })
    return data
  }, [panasType, reports])

  // Filter data based on PANAS type
  const filteredData = useMemo(
    () => getFilteredData(data, panasType),
    [data, panasType]
  )

  // Get heading text based on PANAS type
  const headingText = useMemo(() => getHeadingText(panasType), [panasType])

  // Render table rows
  const renderTableRows = useCallback(() => {
    return filteredData.map((item) => <TableRow key={item.key} item={item} />)
  }, [filteredData])

  /** Component JSX Structure */
  return (
    <>
      {filteredData.length === 0 ? (
        panasType === 'modifiedPanas' ? (
          <NoRecordsView headingText={headingText} />
        ) : null
      ) : (
        <TableView
          headingText={headingText}
          renderTableRows={renderTableRows}
        />
      )}
    </>
  )
}

export default PANAS
