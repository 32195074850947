import { useLocation, useNavigate } from 'react-router-dom'

export default () => {
  const location = useLocation()
  const parts = location.pathname.split('/')
  const step = parseInt(parts[3])
  const nav = useNavigate()

  return () => {
    nav(`/report/${parts[2]}/${step + 1}`)
  }
}
