import { setDoc } from 'firebase/firestore'
import { MonthlyReport } from '../store/useStore'
import useReportDoc from './useReportDoc'

export default () => {
  const doc = useReportDoc()

  return async (partial: Partial<MonthlyReport>) => {
    setDoc(
      doc,
      {
        ...partial,
        id: doc.id
      },
      { merge: true }
    )
  }
}
