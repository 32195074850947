// usePurpleBubbles.ts
import useStore from '../store/useStore'
import { ContentItem } from '../types/contentManagement'

const usePurpleBubblesText = (page: string) => {
  const purpleBubblesData = useStore((s) => s.content?.['purpleBubbles']) || []
  let purpleBubbles: ContentItem[] = []

  if (Array.isArray(purpleBubblesData)) {
    purpleBubbles = purpleBubblesData
  } else if (purpleBubblesData.contentBlocks) {
    purpleBubbles = purpleBubblesData.contentBlocks
  }

  const purpleBubbleContent = purpleBubbles.find((bubble) => bubble.id === page)

  return purpleBubbleContent
}

export default usePurpleBubblesText
