import { FormControl, FormLabel, Select } from '@chakra-ui/react'
import countries from '../utils/countries'

export default ({
  defaultValue,
  onChange
}: {
  defaultValue?: string
  onChange: (country: string) => void
}) => {
  return (
    <FormControl>
      <FormLabel>Country</FormLabel>
      <Select
        placeholder='Choose Country'
        defaultValue={defaultValue}
        onChange={e => onChange(e.target.value)}
      >
        {countries.map(country => {
          return (
            <option value={country} key={country}>
              {country}
            </option>
          )
        })}
      </Select>
    </FormControl>
  )
}
