import { RouteObject } from 'react-router-dom'

import AdminPrint from '../pages/AdminPrint'
import AdminUserPage from '../pages/AdminUserPage'
import ProgressionQuery from '../pages/ProgressionQuery'

const advancedRoutes: RouteObject[] = [
  { path: '/query', element: <ProgressionQuery /> },
  { path: '/users/:id', element: <AdminUserPage /> },
  { path: '/users/:uid/print', element: <AdminPrint /> },
]

export default advancedRoutes
