// useModal.ts
import { useState } from 'react'
import { ContentItem } from '../../../types/contentManagement'

const useModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [status, setStatus] = useState<'adding' | 'editing' | ''>('')
  const [title, setTitle] = useState('')
  const [text, setText] = useState('')

  const openModal = (status: 'adding' | 'editing', content?: ContentItem) => {
    setStatus(status)
    if (content) {
      setTitle(content.id)
      setText(content.text)
    } else {
      setTitle('')
      setText('')
    }
    setIsModalOpen(true)
  }

  const closeModal = () => setIsModalOpen(false)

  return {
    isModalOpen,
    status,
    title,
    text,
    setTitle,
    setText,
    openModal,
    closeModal,
  }
}

export default useModal
