import { range } from 'lodash'
import {
  Heading,
  Link,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { PartialWithFieldValue, doc, setDoc } from 'firebase/firestore'

import { collections } from '../../AAinitFirebase'
import useHandleError from '../../hooks/useHandleError'
import useStore from '../../store/useStore'
import FirestoreUser from '../../types/FirestoreUser'

import TableCell from './TableCell'

// Define a type for the possible course data
type CourseData = {
  startedAt: { month: number; year: number }
  completedAt: { month: number; year: number } | null
}

type Hyperlink = {
  text: string
  link: string
}

const Courses = ({
  editable,
  user,
  courseType,
  courseNames,
  numCourses,
  displayName,
  hyperlink,
}: {
  editable: boolean
  user: FirestoreUser
  courseType: 'bootCamps' | 'essentialsCourses'
  courseNames: string[]
  numCourses: number
  displayName: string
  hyperlink?: Hyperlink | null
}) => {
  const uid = useStore((s) => s.user?.uid)
  const handleError = useHandleError()
  const toast = useToast()

  const updateUserCourses = async (
    userCourseData: PartialWithFieldValue<FirestoreUser>
  ) => {
    try {
      await setDoc(doc(collections.users, uid), userCourseData, {
        merge: true,
      })
    } catch (e) {
      handleError(e)
    }
  }

  const handleModalSubmit = (
    courseCompletionDate: { month: number; year: number },
    courseIndex: number
  ) => {
    const { month, year } = courseCompletionDate
    if (year < 2024 || (year === 2024 && month < 10)) {
      toast({
        title: 'Invalid Date',
        description: 'Completion date cannot be before October 2024.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return
    }

    const courseData = (
      user[courseType] as Partial<Record<number, CourseData | null>>
    )?.[courseIndex]

    updateUserCourses({
      [courseType]: {
        [courseIndex]: {
          ...courseData,
          completedAt: courseCompletionDate,
        },
      },
    })
  }

  const handleUncheck = (courseIndex: number, courseData: CourseData) => {
    updateUserCourses({
      [courseType]: {
        [courseIndex]: {
          ...courseData,
          completedAt: null,
        },
      },
    })
  }

  return (
    <Stack>
      <Heading fontSize="md">{displayName}</Heading>
      <Text>Which {displayName} have you completed?</Text>
      {hyperlink && (
        <Link href={hyperlink.link} isExternal color="purple.600">
          {hyperlink.text}
        </Link>
      )}
      <TableContainer>
        <Table size="sm" variant="simple">
          <Thead>
            <Tr>
              <Th width="80%"></Th>
              <Th>Completed</Th>
            </Tr>
          </Thead>
          <Tbody>
            {range(0, numCourses).map((courseIndex) => {
              if (!uid) return null

              const courseData = (
                user[courseType] as Partial<Record<number, CourseData | null>>
              )?.[courseIndex]
              const courseName = courseNames?.[courseIndex]

              return (
                <Tr key={courseIndex}>
                  <Td>{courseName}</Td>
                  <TableCell
                    value={courseData?.completedAt || null}
                    onUncheck={() => handleUncheck(courseIndex, courseData!)}
                    editable={editable}
                    onCheck={(date) => {
                      handleModalSubmit(date, courseIndex)
                    }}
                    modalTitle={`${displayName} Complete`}
                    modalSubtitle={`When did you complete ${courseNames[courseIndex]}?`}
                  />
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  )
}

export default Courses
