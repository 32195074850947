export const getDietStatus = (report: any) => {
  const dietsComplete = !!report?.diets
  const waterComplete = !!report?.waterAmount && !!report?.waterType
  const macroComplete =
    !!report?.macros?.protienGrams &&
    !!report?.macros?.fatGrams &&
    !!report?.macros?.carbGrams
  const carbDietComplete = !!report?.carbStyleDiet
  const dairyComplete = !!report?.dairyStyleDiet
  const grainComplete = !!report?.grainStyleDiet

  const allComplete =
    dietsComplete &&
    waterComplete &&
    macroComplete &&
    carbDietComplete &&
    dairyComplete &&
    grainComplete
  const anyComplete =
    dietsComplete ||
    waterComplete ||
    macroComplete ||
    carbDietComplete ||
    dairyComplete ||
    grainComplete

  if (allComplete) {
    return 'complete'
  } else if (anyComplete) {
    return 'inProgress'
  } else {
    return 'unstarted'
  }
}
