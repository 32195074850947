import {
  Box,
  Heading,
  HStack,
  Stack,
  Text,
  useToast,
  useToken
} from '@chakra-ui/react'
import {
  FaChartPie,
  FaCheckCircle,
  FaCircleNotch,
  FaVial
} from 'react-icons/fa'

import { Link } from 'react-router-dom'
import useProfile from '../hooks/useProfile'

export default ({
  title,
  subtitle,
  status,
  to
}: {
  title: string
  subtitle?: string
  status?: 'unstarted' | 'inProgress' | 'complete' | 'bloodWork'
  to: string
}) => {
  const profile = useProfile()
  const toast = useToast()
  const [green, yellow, gray] = useToken('colors', [
    'green.500',
    'yellow.500',
    'gray.500'
  ])

  const content = (
    <HStack
      h='100%'
      borderWidth='1px'
      borderRadius='lg'
      padding='5'
      _hover={{
        background: 'gray.100'
      }}
      spacing={2}
      justifyContent='space-between'
      alignItems='center'
    >
      <Stack spacing={1}>
        <Heading size='sm'>{title}</Heading>
        {subtitle && <Text fontSize='sm'>{subtitle}</Text>}
      </Stack>
      {status === 'unstarted' && (
        <FaCircleNotch fontSize={24} color={gray} />
      )}
      {status === 'inProgress' && (
        <FaChartPie fontSize={24} color={yellow} />
      )}
      {status === 'complete' && (
        <FaCheckCircle fontSize={24} color={green} />
      )}
      {status === 'bloodWork' && <FaVial fontSize={24} color={gray} />}
    </HStack>
  )

  return profile ? (
    <Link to={to}>{content}</Link>
  ) : (
    <Box
      cursor='pointer'
      onClick={() => {
        toast({
          status: 'error',
          isClosable: true,
          title: 'Profile Missing',
          description:
            "You can't start doing reports until you create your profile."
        })
      }}
    >
      {content}
    </Box>
  )
}
