import { Box, Button, Checkbox, Stack } from '@chakra-ui/react'
import {
  CollectionReference,
  collection,
  getDocs,
  getFirestore,
  query,
  where
} from 'firebase/firestore'
import { chain } from 'lodash'
import { useState } from 'react'
import { CSVDownload } from 'react-csv'
import useHandleError from '../hooks/useHandleError'
import FirestoreUser, { diagnosisTitles } from '../types/FirestoreUser'

export default () => {
  const [data, setData] = useState<any>()
  const [loading, setLoading] = useState(false)
  const handleError = useHandleError()
  const [includeDeceased, setIncludeDeceased] = useState(false)
  return (
    <Stack>
      <Checkbox
        isChecked={includeDeceased}
        onChange={e => setIncludeDeceased(e.target.checked)}
      >
        Include Deceased
      </Checkbox>
      <Box>
        <Button
          isLoading={loading}
          isDisabled={loading}
          onClick={async () => {
            setLoading(true)
            try {
              const snap = await getDocs(
                query(
                  collection(
                    getFirestore(),
                    'users'
                  ) as CollectionReference<FirestoreUser>,
                  where('profile', '!=', null)
                )
              )
              console.log(snap.size)

              setData(
                chain(snap.docs)
                  .map(doc => doc.data())
                  .filter(user => {
                    if (includeDeceased) return true
                    return !user.lockedDate
                  })
                  .orderBy(user => user.email, 'asc')
                  .map(user => {
                    return {
                      Email: user.email,
                      'First Name': user.profile?.firstName,
                      'Last Name': user.profile?.lastName,
                      Diagnosis:
                        diagnosisTitles[
                          user.profile?.diagnosis || 'administrator'
                        ],
                      Deceased: user.lockedDate ? 'YES' : 'NO'
                    }
                  })
                  .value()
              )
            } catch (e) {
              handleError(e)
            } finally {
              setLoading(false)
            }
          }}
        >
          Export to CSV
        </Button>
      </Box>
      {data && (
        <CSVDownload
          data={data}
          target='_blank'
          filename='ALS_Registry_Users.csv'
        />
      )}
    </Stack>
  )
}
