import { FormControl, FormLabel, Input } from '@chakra-ui/react'

interface FormInputProps {
  label?: string
  type: string
  value: number | string
  isRequired?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  inputWidth?: string | number
  width?: string | number
}

const FormInput: React.FC<FormInputProps> = ({
  label,
  type,
  value,
  onChange,
  isRequired = true,
  inputWidth = '50%',
  width = '100%',
}) => (
  <FormControl isRequired={isRequired} width={width}>
    {label && <FormLabel>{label}</FormLabel>}
    <Input
      placeholder={label || ''}
      type={type}
      value={value}
      onChange={onChange}
      width={inputWidth}
    />
  </FormControl>
)

export default FormInput
