import useStore from '../../store/useStore'
import FirestoreUser from '../../types/FirestoreUser'
import Courses from './Courses'

const Bootcamps = ({
  editable,
  user,
}: {
  editable: boolean
  user: FirestoreUser
}) => {
  const numBootcamps = useStore((s) => s.meta?.numBootcamps || 0)
  const bootcampNames = useStore((s) => s.meta?.bootcampNames || [])
  const userBootcamps = user.bootCamps || {}

  // Check if any Bootcamps have been completed
  const hasCompletedBootcamps = Object.values(userBootcamps).some(
    (bootcamp) => bootcamp?.completedAt
  )

  // If no Bootcamps have been completed, do not render the component
  if (!hasCompletedBootcamps) {
    return null
  }

  return (
    <Courses
      editable={editable}
      user={user}
      courseType="bootCamps"
      courseNames={bootcampNames}
      numCourses={numBootcamps}
      displayName="Bootcamps"
      hyperlink={null}
    />
  )
}

export default Bootcamps
