import { map } from 'lodash'
import Questionnaire from '../types/Questionnaire'

export const alssq: Questionnaire = {
  key: 'alssq',
  title: 'ALSSQ',
  subtitle: 'Update your ALS Supplemental Questionnaire',
  questions: {
    energy: {
      title: 'What is your energy level throughout the day?',
      options: [
        [
          {
            text: 'Excellent',
            points: 0
          },
          {
            text: 'Good',
            points: 0
          },
          {
            text: 'Moderate',
            points: 0
          },
          {
            text: 'Low',
            points: 0
          },
          {
            text: 'Barely There',
            points: 0
          }
        ]
      ]
    },

    energyAfterExertion: {
      title:
        'What is your energy level after physical exertion (you moving or someone moving you)?',
      options: [
        [
          { text: 'Excellent', points: 0 },
          { text: 'Good', points: 0 },
          { text: 'Moderate', points: 0 },
          { text: 'Low', points: 0 },
          { text: 'Barely there', points: 0 }
        ]
      ]
    },
    clarity: {
      title: 'What is your mental clarity?',
      options: [
        [
          { text: 'Excellent', points: 0 },
          { text: 'Good', points: 0 },
          { text: 'Moderate', points: 0 },
          { text: 'Low', points: 0 },
          { text: 'Barely there', points: 0 }
        ]
      ]
    },
    pain: {
      title: 'Level of pain out of 10',
      options: [
        [
          {
            text: 'None',
            points: 0
          },
          {
            text: '1-3',
            points: 0
          },
          {
            text: '4-5',
            points: 0
          },
          {
            text: '6-7',
            points: 0
          },
          {
            text: '8-10',
            points: 0
          }
        ]
      ]
    },
    painFrequency: {
      title:
        'How often to you experience pain? Do not include cramping or injury-related pain',
      options: [
        [
          {
            text: 'Never',
            points: 0
          },
          {
            text: 'Sometimes or only after an activity',
            points: 0
          },
          {
            text: 'Moderately',
            points: 0
          },
          {
            text: 'Frequently',
            points: 0
          },
          {
            text: 'Always',
            points: 0
          }
        ]
      ]
    },
    painLocation: {
      title: 'Location of pain',
      options: [
        [
          {
            text: 'None',
            points: 0
          },
          {
            text: 'In 1-2 areas of the body',
            points: 0
          },
          {
            text: 'In 3-4 areas of the body',
            points: 0
          },
          {
            text: 'In 5-6 areas of the body',
            points: 0
          },
          {
            text: 'Throughout the body',
            points: 0
          }
        ]
      ]
    },
    twitching: {
      title: 'Frequency of fasciculation/twitching',
      options: [
        [
          {
            text: 'Never',
            points: 0
          },
          {
            text: 'Occasionally',
            points: 0
          },
          {
            text: 'Moderately',
            points: 0
          },
          {
            text: 'Frequently',
            points: 0
          },
          {
            text: 'Most of the time',
            points: 0
          }
        ]
      ]
    },
    twitchingSeverity: {
      title: 'Severity of fasciculation/twitching',
      options: [
        [
          {
            text: 'None',
            points: 0
          },
          {
            text: 'Very Mild',
            points: 0
          },
          {
            text: 'Mild',
            points: 0
          },
          {
            text: 'Moderate',
            points: 0
          },
          {
            text: 'Severe',
            points: 0
          }
        ]
      ]
    },
    cramping: {
      title: 'Frequency of muscle cramping/spasms',
      options: [
        [
          {
            text: 'Never',
            points: 0
          },
          {
            text: 'Occasionally',
            points: 0
          },
          {
            text: 'Moderately',
            points: 0
          },
          {
            text: 'Frequently',
            points: 0
          },
          {
            text: 'Most of the time',
            points: 0
          }
        ]
      ]
    },
    crampingSeverity: {
      title: 'Severity of muscle cramping/spasms',
      options: [
        [
          {
            text: 'None',
            points: 0
          },
          {
            text: 'Very Mild',
            points: 0
          },
          {
            text: 'Mild',
            points: 0
          },
          {
            text: 'Moderate',
            points: 0
          },
          {
            text: 'Severe',
            points: 0
          }
        ]
      ]
    },
    sleepHours: {
      title: 'How many hours do you sleep?',
      options: [
        [
          {
            text: '7 to 8 hours, no naps',
            points: 0
          },
          {
            text: '7 to 10 hours, no naps',
            points: 0
          },
          {
            text: '7 to 10 hours plus a nap',
            points: 0
          },
          {
            text: 'Either less than 7 hours or more than 12 hours total',
            points: 0
          },
          {
            text: 'Either less than 6 hours or more than 13 hours total',
            points: 0
          }
        ]
      ]
    },
    sleepWaking: {
      title: 'Sleep: Number of times waking up nightly',
      options: [
        [
          {
            text: '0-1',
            points: 0
          },
          {
            text: '2-3',
            points: 0
          },
          {
            text: '4-5',
            points: 0
          },
          {
            text: '6-7',
            points: 0
          },
          {
            text: '8 or more',
            points: 0
          }
        ]
      ]
    },
    sleepQuality: {
      title: 'Sleep quality',
      options: [
        [
          {
            text: 'Great. I wake up rested',
            points: 0
          },
          {
            text: 'Good. I wake up rested most of the time',
            points: 0
          },
          {
            text: 'Medium. I feel okay',
            points: 0
          },
          {
            text: 'Poor. I wake up tired.',
            points: 0
          },
          {
            text: 'Very poor. I am exhausted most of the time',
            points: 0
          }
        ]
      ]
    },
    mucous: {
      title:
        'Mucous in your nose or throat (thick secretion, not saliva. Saliva is thinner and covered in the ALSFRS-R.)',
      options: [
        [
          {
            text: 'Never',
            points: 0
          },
          {
            text: 'Occasionally',
            points: 0
          },
          {
            text: 'Moderately',
            points: 0
          },
          {
            text: 'Frequently',
            points: 0
          },
          {
            text: 'Most of the time',
            points: 0
          }
        ]
      ]
    },
    mucousChest: {
      title: 'Mucous in your chest',
      options: [
        [
          {
            text: 'Never',
            points: 0
          },
          {
            text: 'Occasionally',
            points: 0
          },
          {
            text: 'Moderately',
            points: 0
          },
          {
            text: 'Frequently',
            points: 0
          },
          {
            text: 'Most of the time',
            points: 0
          }
        ]
      ]
    },
    swelling: {
      title: 'Foot and/or hand swelling',
      options: [
        [
          {
            text: 'Never',
            points: 0
          },
          {
            text: 'Occasionally',
            points: 0
          },
          {
            text: 'Moderately',
            points: 0
          },
          {
            text: 'Frequently',
            points: 0
          },
          {
            text: 'Most of the time',
            points: 0
          }
        ]
      ]
    },
    headUp: {
      title: 'Can you hold your head up?',
      options: [
        [
          {
            text: 'Yes, no problem',
            points: 0
          },
          {
            text: 'Yes, almost all of the time',
            points: 0
          },
          {
            text: 'Yes, for short periods of time',
            points: 0
          },
          {
            text: 'Sometimes',
            points: 0
          },
          {
            text: 'Never',
            points: 0
          }
        ]
      ]
    },
    laughing: {
      title: 'Do you laugh/cry inappropriately?',
      options: [
        [
          {
            text: 'Never',
            points: 0
          },
          {
            text: 'Occasionally',
            points: 0
          },
          {
            text: 'Moderately',
            points: 0
          },
          {
            text: 'Frequently',
            points: 0
          },
          {
            text: 'Most of the time',
            points: 0
          }
        ]
      ]
    }
  }
}

map(alssq.questions, question => {
  question.options.forEach(set => {
    set.forEach((option, index) => {
      option.points = set.length - (index + 1)
    })
  })
})
